import { IFilterService } from "angular";
import { DayOfWeek, LocalTime } from "js-joda";

export const daysOfWeekDisplay = {
  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday"
}


export function parseFlexibleDaysAndHoursVisitHoursPattern(visitDetails: {
    amountOfDays: number,
    amountOfHours: number
}[]){
    return visitDetails.map((detail) => `${detail.amountOfDays} Days of ${detail.amountOfHours} Hours.`)
}

export function parseFixedDaysFlexibleHoursVisitHoursPattern(visitDetails: {
    day: DayOfWeek
    amountOfHours: number
}[]){
    return visitDetails.map((detail) => `${detail.amountOfHours} Hours On ${daysOfWeekDisplay[detail.day.toString()]}.`);
}

export function parseFixedHoursFlexibleDaysPattern(timeFilter: IFilterService, visitDetails: {
    day: DayOfWeek,
    startTime: LocalTime,
    endTime: LocalTime
}[]){
    return visitDetails.map((detail) => `${daysOfWeekDisplay[detail.day.toString()]}: ${timeFilter("mfShortTime")(
        detail.startTime
      )} - ${timeFilter("mfShortTime")(detail.endTime)}.`);
}