import { LocalDate, LocalDateTime } from "js-joda";
import { match } from "ts-pattern";
import { apiErrors } from "../../../../scripts/consts/apiErrors.const";
import { dateUtils } from "../../../../scripts/consts/dateUtils";
import languages from "../../../../scripts/consts/languages";
import { loadable, Loadable } from "../../../../scripts/consts/loadable.const";
import {
  CompliancePrioritizedCaregiverByPendingDocuments,
  CompliancePriorityPendingDocument,
  ComplianceV2Field,
  ComplianceV2FieldInstance,
  ComplianceV2FieldInstanceWithValue,
} from "../../../../scripts/messages/compliance";
import {
  CaregiverDocumentTypeId,
  CaregiverDocumentUploadId,
  ComplianceV2ItemFieldPossibleValueId,
} from "../../../../scripts/messages/ids";
import { DatabaseApiService } from "../../../../scripts/services/db";
import { MfModalFactory } from "../../../../scripts/services/mfModalFactory";
import { arrayAt, assertDefined, fmap } from "../../../../scripts/utils/generalUtils";
import { CaregiverService } from "../../../caregiver/services/caregiver.service";
import { MultiselectOption } from "../../../shared/components/editable-label/editable-label.component";
import { CompService } from "../../comp.service";
import "./prioritized-caregivers.component.scss";

interface ActiveDocumentState {
  isCompliant: boolean;
  effectiveDate: Date;
  expiryDate: Date | null;
  fields: WithNgModel<ComplianceV2FieldInstance>[];
  expires: boolean;
  requireReVerification: boolean;
  files: null;
  expiryDateButtons: {
    title: string;
    active: boolean;
    date: Date;
  }[];
  ignoreFiles: boolean;
  copyFromCaregiverDocumentUploadId?: CaregiverDocumentUploadId;
}

interface SwapDocumentType {
  model: {
    id: CaregiverDocumentTypeId | null;
  }[];
  isTrue: boolean;
}

interface ComponentOptions extends angular.IComponentOptions {
  $name: string;
}

type WithNgModel<$Field extends ComplianceV2Field> = $Field extends ComplianceV2Field.Date
  ? $Field & { model: { value: Date | null } }
  : $Field & { model: string | null };

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "PrioritizedCaregiversScreen";

  row: Loadable<CompliancePrioritizedCaregiverByPendingDocuments> = loadable.loading();
  activeDocument: Loadable<CompliancePriorityPendingDocument> = loadable.loading();
  documentsState: Map<CaregiverDocumentTypeId, ActiveDocumentState> = new Map();
  dropdownDocumentOptions: { id: CaregiverDocumentTypeId; label: string }[] = [];
  documentTypeIdToName: Map<CaregiverDocumentTypeId, string> = new Map();
  swapDocumentType: SwapDocumentType = {
    isTrue: false,
    model: [],
  };
  isEditMode = false;
  agencyCertificationsOptions?: MultiselectOption[];
  languagesOptions = languages.map((language) => ({
    id: language.value,
    label: language.text,
  }));
  genderOptions = [
    { id: "M", label: "Male" },
    { id: "F", label: "Female" },
  ];

  get canGoToPreviousDocument(): boolean {
    return this.getPreviousDocumentOrNull() !== null;
  }

  get canGoToNextDocument(): boolean {
    return this.getNextDocumentOrNull() !== null;
  }

  constructor(
    private mfModal: MfModalFactory,
    private compService: CompService,
    private $uibModal: ng.ui.bootstrap.IModalService,
    private $rootScope: angular.IRootScopeService,
    private DatabaseApi: DatabaseApiService,
    private caregiverService: CaregiverService
  ) {}

  $onInit(): void {
    this.fetchData();
    this.fetchAgencyCertifications();
    this.$rootScope.$on("got_agency_certifications", () => this.fetchAgencyCertifications());
  }

  goToPreviousDocument() {
    const previousDocument = this.getPreviousDocumentOrNull();

    if (previousDocument !== null) {
      this.activeDocument = loadable.resolve(previousDocument);
    }
  }

  goToNextDocument() {
    const nextDocument = this.getNextDocumentOrNull();

    if (nextDocument !== null) {
      this.activeDocument = loadable.resolve(nextDocument);
    }
  }

  setActiveDocument(document: CompliancePriorityPendingDocument) {
    this.activeDocument = loadable.resolve(document);
  }

  getPreviousDocumentOrNull(): CompliancePriorityPendingDocument | null {
    return match({ row: this.row, activeDocument: this.activeDocument })
      .with({ row: { type: "Resolved" }, activeDocument: { type: "Resolved" } }, (x) => {
        const index = x.row.value.documents.indexOf(x.activeDocument.value);
        return x.row.value.documents[index - 1] ?? null;
      })
      .otherwise(() => null);
  }

  getNextDocumentOrNull(): CompliancePriorityPendingDocument | null {
    return match({ row: this.row, activeDocument: this.activeDocument })
      .with({ row: { type: "Resolved" }, activeDocument: { type: "Resolved" } }, (x) => {
        return (
          x.row.value.documents[x.row.value.documents.indexOf(x.activeDocument.value) + 1] ?? null
        );
      })
      .otherwise(() => null);
  }

  updateCaregiverField = (field: string, value: unknown) => {
    if (!loadable.isResolved(this.row)) {
      console.error("row is not resolved");
      return;
    }

    this.caregiverService
      .updateField(this.row.value.caregiver.id, field, value)
      .then(() => {
        this.promptSuccessModal("Caregiver updated successfully");
        this.fetchData();
      })
      .catch((error) => {
        console.error(error);
        this.promptErrorModal(error);
      });
  }

  promptSkip() {
    const modal = this.mfModal.create({
      variant: "warning",
      subject: "Skip caregiver",
      message:
        "Are you sure you want to skip this caregiver? You won't be able to see this caregiver again in the next 30 minutes.",
      onConfirm: async () => {
        if (!loadable.isResolved(this.row)) {
          console.error("row is not resolved");
          return;
        }

        modal.setLoading(true);

        this.compService
          .skipPendingCaregiver({ caregiverId: this.row.value.caregiver.id })
          .then(() => this.fetchData())
          .then(() => modal.close())
          .catch((error) => {
            console.error(error);
            modal.close();
            this.promptErrorModal(error);
          });
      },
    });
  }

  promptReject() {
    const modal = this.mfModal.create({
      variant: "warning",
      subject: "Reject document",
      showInput: true,
      inputIsRequired: false,
      inputType: "text",
      inputPlaceholder: "Enter a reason for rejecting this document",
      message:
        "Are you sure you want to reject this document? The caregiver will be notified and will be able to upload a new document.",
      onConfirm: async ({ inputModel }) => {
        if (!loadable.isResolved(this.row) || !loadable.isResolved(this.activeDocument)) {
          console.error("row or activeDocument is not resolved");
          return;
        }

        modal.setLoading(true);

        this.compService
          .rejectDocument({
            caregiverId: this.row.value.caregiver.id,
            uploadedDocumentId: this.activeDocument.value.caregiverDocumentUploadId,
            reason: inputModel ?? null,
          })
          .then(() => this.fetchData())
          .catch((error) => this.promptErrorModal(error))
          .finally(() => modal.close());
      },
    });
  }

  promptApprove() {
    const modal = this.mfModal.create({
      subject: "Approve document",
      message: "Are you sure you want to approve this document?",
      onConfirm: async () => {
        if (!loadable.isResolved(this.row) || !loadable.isResolved(this.activeDocument)) {
          console.error("row is not resolved");
          return;
        }

        const { documentTypeId, activeDocumentState, wasSwapped } = (() => {
          const state = assertDefined(
            this.documentsState.get(this.activeDocument.value.caregiverDocumentTypeId),
            "activeDocumentState"
          );

          if (this.swapDocumentType.isTrue) {
            const newDocumentTypeId = arrayAt(this.swapDocumentType.model, 0)?.id ?? null;

            if (newDocumentTypeId === null) {
              throw new Error("Pleae select a document type");
            }

            return {
              documentTypeId: newDocumentTypeId,
              activeDocumentState: assertDefined(
                this.documentsState.get(newDocumentTypeId),
                "activeDocumentState (newDocumentTypeId)"
              ),
              wasSwapped: true,
            };
          }

          return {
            documentTypeId: this.activeDocument.value.caregiverDocumentTypeId,
            activeDocumentState: state,
            wasSwapped: false,
          };
        })();

        modal.setLoading(true);

        this.compService
          .createComplianceInstance({
            caregiverId: this.row.value.caregiver.id,
            data: {
              caregiverDocumentTypeId: documentTypeId,
              effectiveDate: dateUtils.dateToLocalDate(activeDocumentState.effectiveDate),
              isCompliant: activeDocumentState.isCompliant,
              caregiverDocumentUploadId: this.activeDocument.value.caregiverDocumentUploadId,
              shouldDuplicateUploadForFollowup: true,
              expiryDate:
                fmap(activeDocumentState.expiryDate, dateUtils.dateToLocalDate) ?? undefined,
              duplicateDocumentUpload: activeDocumentState.copyFromCaregiverDocumentUploadId,
              swapUploadedDocumentTypeId: wasSwapped,
              fields: activeDocumentState.fields.map((field) =>
                match(field)
                  .with(
                    { type: "Text" },
                    (field): ComplianceV2FieldInstanceWithValue => ({
                      id: field.id,
                      type: field.type,
                      value: field.model,
                      isMandatory: field.isMandatory,
                      name: field.name,
                    })
                  )
                  .with(
                    { type: "Dropdown" },
                    (field): ComplianceV2FieldInstanceWithValue => ({
                      id: field.id,
                      type: field.type,
                      value: fmap(
                        field.model,
                        (idAsStr) =>
                          field.possibleValues.find(({ id }) => {
                            return (
                              id === ComplianceV2ItemFieldPossibleValueId.wrap(parseInt(idAsStr))
                            );
                          }) ?? null
                      ),
                      isMandatory: field.isMandatory,
                      name: field.name,
                      possibleValues: field.possibleValues,
                    })
                  )
                  .with(
                    { type: "Date" },
                    (field): ComplianceV2FieldInstanceWithValue => ({
                      id: field.id,
                      type: field.type,
                      value: fmap(field.model.value, dateUtils.dateToLocalDate),
                      isMandatory: field.isMandatory,
                      name: field.name,
                    })
                  )
                  .exhaustive()
              ),
            },
          })
          .then(() => {
            this.swapDocumentType = {
              isTrue: false,
              model: [],
            };

            if (
              loadable.isResolved(this.row) &&
              this.isLastCaregiverPendingDocument(this.row.value)
            ) {
              this.promptCaregiverStatusChangeModal(this.row.value.caregiver, true);
            } else {
              this.fetchData();
            }
          })
          .then(() => modal.close())
          .catch((error) => {
            modal.close();
            this.promptErrorModal(error);
          });
      },
    });
  }

  promptErrorModal(error: Error) {
    const modal = this.mfModal.create({
      variant: "danger",
      subject: "Error",
      message: apiErrors.format(error, "Something went wrong"),
      confirmLabel: "Close",
      onConfirm: () => modal.close(),
      hideCancelButton: true,
    });
  }

  promptSuccessModal(message: string) {
    const modal = this.mfModal.create({
      subject: "Success!",
      message: message,
      confirmLabel: "Close",
      hideCancelButton: true,
      onConfirm: () => modal.close(),
    });
  }

  promptCaregiverStatusChangeModal(
    caregiver: CompliancePrioritizedCaregiverByPendingDocuments["caregiver"],
    showCompliantMessage: boolean
  ) {
    this.$uibModal
      .open({
        templateUrl: "admin/views/edit-caregiver-status-modal.html",
        size: "md",
        controller: "editCaregiverStatusModalCtrl",
        windowClass: "center-center",
        resolve: {
          caregiver: caregiver,
          isFromPrioritizedCaregeiversScreen: showCompliantMessage,
        },
      })
      .result.then(() => this.fetchData());
  }

  isLastCaregiverPendingDocument(
    caregiverDocuments: CompliancePrioritizedCaregiverByPendingDocuments
  ) {
    return caregiverDocuments.documents.length === 1;
  }

  formatLocalDateTime = (localDateTime: LocalDateTime) =>
    dateUtils.localDateTimeToMDYHMString(localDateTime);

  private async fetchData() {
    return this.compService
      .nextPendingCaregiver()
      .then((data) => {
        if (data === null) {
          this.row = loadable.reject("No more pending caregivers");
          return;
        }

        this.row = loadable.resolve(data);
        this.activeDocument = loadable.resolve(data.documents[0]);
        this.dropdownDocumentOptions = this.row.value.documentTypes.map((documentType) => ({
          id: documentType.id,
          label: documentType.name,
        }));
        this.row.value.documentTypes.forEach((documentType) => {
          this.documentTypeIdToName.set(documentType.id, documentType.name);
        });
        this.row.value.documentTypes.forEach((document) => {
          this.documentsState.set(document.id, {
            isCompliant: false,
            effectiveDate: new Date(),
            expiryDate: null,
            fields: document.fields.map((field) => {
              return match(field)
                .with({ type: "Text" }, (field) => ({ ...field, model: null }))
                .with({ type: "Dropdown" }, (field) => ({ ...field, model: null }))
                .with({ type: "Date" }, (field) => ({ ...field, model: { value: null } }))
                .exhaustive();
            }),
            expires: document.expires,
            requireReVerification: document.requireReVerification,
            files: null,
            ignoreFiles: true,
            expiryDateButtons: [
              {
                title: "1 week",
                active: false,
                date: dateUtils.localDateToDate(LocalDate.now().plusWeeks(1)),
              },
              {
                title: "1 month",
                active: false,
                date: dateUtils.localDateToDate(LocalDate.now().plusMonths(1)),
              },
              {
                title: "1 year",
                active: false,
                date: dateUtils.localDateToDate(LocalDate.now().plusYears(1)),
              },
              {
                title: "Custom date",
                active: false,
                date: new Date(),
              },
            ],
          });
        });
      })
      .catch((error) => {
        console.error(error);
        this.promptErrorModal(error);
        this.row = apiErrors.format(error, "Something went wrong");
      });
  }

  private fetchAgencyCertifications = () => {
    this.agencyCertificationsOptions = this.DatabaseApi.activeAgencyCertifications().map((val) => ({
      id: val.certification,
      label: val.certification,
    }));
  };

  toggleEditMode = () => (this.isEditMode = !this.isEditMode);
}

export const PrioritizedCaregiversScreenComponent: ComponentOptions = {
  $name: "prioritizedCaregiversScreen",
  templateUrl:
    "admin/modules/compliance/components/prioritized-caregivers/prioritized-caregivers.component.html",
  controller: Controller,
  controllerAs: "ctrl",
};
