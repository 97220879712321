import { Instant, LocalDate, nativeJs } from "js-joda";
import JSZip from "jszip";
import moment from "moment";
import phoneUtils from "../utils/phoneUtils";
import download from "downloadjs";
import languages from "../consts/languages";

//! @ngInject
export function professionalCtrl(
  GoogleAddressService,
  $http,
  $scope,
  $rootScope,
  NgTableParams,
  $state,
  Lightbox,
  pro,
  DatabaseApi,
  toaster,
  $filter,
  Consts,
  Analytics,
  $stateParams,
  $window,
  $uibModal,
  $timeout,
  VisitStatus,
  entityNotesModalService,
  $uibModalInstance,
  $compile,
  SweetAlert,
  offices,
  officesService,
  agencyBranchService,
  CaregiverModalService,
  generalUtils,
  complianceConsts,
  OrientationCenterService,
  caregiverTrainingCenterAddBundleModalService,
  agencyCustomFieldsService,
  wildcard,
  applicationLandingCertification,
  entityNewVisitModalService,
  mfModal,
  VisitInstanceModalService,
  exportProfileService,
  CaregiverStatus
) {
  $scope.CaregiverStatus = CaregiverStatus;
  $scope.activeLang = { val: 'English' };
  $scope.langs = [
    "English",
    "Spanish",
    "Russian",
    "Mandarin",
    "Chinese",
    "French",
    "Arabic",
    "Creole"
  ];

  $scope.statuses = [
    { id: 1, value: "ACTIVE", text: "Active", statusClass: "green" },
    { id: 2, value: "ON_HOLD", text: "On Hold", statusClass: "orange" },
    { id: 3, value: "ON_LEAVE", text: "On Leave", statusClass: "orange" },
    { id: 4, value: "PENDING", text: "Pending Application", statusClass: "lightblue" },
    { id: 5, value: "TERMINATED", text: "Terminated", statusClass: "red" },
    { id: 6, value: "SUSPENDED", text: "Inactive", statusClass: "gray" },
    { id: 7, value: "QUIT", text: "Quit", statusClass: "gray" },
    { id: 7, value: "REJECTED", text: "Rejected", statusClass: "red" }
  ];

  $scope.statusChangeDisabled = !$rootScope.user.permissions.includes('edit_caregiver_status')

  $scope.statusExtraSettings = {
    styleActive: true,
    singleSelection: true,
    selectionLimit: 1,
    smartButtonMaxItems: 1,
    closeOnSelect: true,
    displayProp: 'text'
  };

  $scope.sectionLabelsMap = {
    "general-doc": "General",
    "medical-doc": "Medical"
  }

  $scope.fieldTypesMap = complianceConsts.fieldTypesMap;
  $scope.isOnboardingAgency = $rootScope.isOnboardingAgency;

  $scope.offices = offices.map(office => ({
    id: office.id,
    label: office.name
  }));

  const agencyBranches = DatabaseApi.agencyBranches() || [];
  $scope.agencyBranches = agencyBranches.map(branch => ({
    id: branch.id,
    label: branch.name
  }))

  const agencyCustomFields = DatabaseApi.agencyCustomFields();
  $scope.customFields = angular.copy(agencyCustomFields.filter(customField => customField.entityName === 'CAREGIVER'));

  $scope.handleCustomFieldRemoved = (customField) => {
    agencyCustomFieldsService
      .removeCaregiverCustomField(
        $rootScope.agencyId,
        $scope.profile.id,
        customField
      )
      .then(() => {
        toaster.pop(
          "success",
          "Success",
          `${customField.fieldName} changed successfully`
        );
      })
      .catch(() => {
        toaster.pop(
          "error",
          "Something went wrong",
          `could not edit ${customField.fieldName}`
        );
      });
  };

  $scope.handleCustomFieldChange = (customField) => {
    agencyCustomFieldsService
      .upsertCaregiverCustomField(
        $rootScope.agencyId,
        $scope.profile.id,
        customField
      )
      .then(() => {
        toaster.pop(
          "success",
          "Success",
          `${customField.fieldName} changed successfully`
        );
      })
      .catch(() => {
        toaster.pop(
          "error",
          "Something went wrong",
          `could not edit ${customField.fieldName}`
        );
      });
  };

  const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
  $scope.agencyCertifications = activeAgencyCertifications
    .map((certificationItem, index) => ({
      id: index,
      label: certificationItem.certification
    }));

  if ($state.current.name === 'app.singleProfessionals' && (!$scope.$resolve || !$scope.$resolve.forceModal)) {
    var proId = $stateParams.id;
    $scope.isModal = false;
    $window.scrollTo(0, 0);
  } else {
    var proId = $scope.$resolve.id;
    $scope.isModal = true;
    pro = $scope.$resolve.pro;
  }
  $scope.caregiverId = proId;

  $scope.closeModal = function () {
    $uibModalInstance.close();
  };

  //window.scrollTo(0,0);

  $scope.numberOfFilesToShow = 1;
  $scope.ToggleShow = true;
  $scope.caregiverSectionStageId = null;

  const caregiverCalendarShowDeletedVisits = localStorage.getItem("caregiverCalendarShowDeletedVisits");
  if (caregiverCalendarShowDeletedVisits === null) {
    $scope.showDeletedVisitsOnCalendar = true;
    localStorage.setItem("caregiverCalendarShowDeletedVisits", true);
  } else {
    $scope.showDeletedVisitsOnCalendar = JSON.parse(caregiverCalendarShowDeletedVisits)
  }

  const caregiverCalendarShowSummaryColumn = localStorage.getItem("caregiverCalendarShowSummaryColumn");
  if (caregiverCalendarShowSummaryColumn === null) {
      $scope.showSummaryColumn = true;
  } else {
      $scope.showSummaryColumn = JSON.parse(caregiverCalendarShowSummaryColumn);
  }

  var allDocs;
  var scrollToOptions = {
    duration: 500,
    easing: 'easeOutQuint',
    offset: 175
  };

  $scope.navigationItems = {
    active: 'scroll-about',
    list: [
      {
        id: 'scroll-about',
        title: 'About',
        hidePending: false,
        permissionKey: 'view_caregiver_page_about'
      },
      {
        id: 'scroll-calendar',
        title: 'Calendar',
        hidePending: true,
        permissionKey: 'view_caregiver_page_calendar'
      },
      {
        id: 'scroll-assigned-visits',
        title: 'Assigned visits',
        hidePending: true,
        permissionKey: 'view_caregiver_page_assigned_visits'
      },
      {
        id: 'scroll-compliance',
        title: 'Compliance',
        hidePending: false,
        permissionKey: 'view_caregiver_page_compliance'
      },
      {
        id: 'scroll-orientation',
        title: 'Training Center',
        hidePending: true,
        permissionKey: 'view_caregiver_page_training_center'
      },
      {
        id: 'scroll-availability',
        title: 'Availability',
        hidePending: false,
        permissionKey: 'view_caregiver_page_availability'
      },
      {
        id: 'scroll-attendance',
        title: 'Attendance',
        hidePending: false,
        permissionKey: 'view_caregiver_page_attendance'
      },
      {
        id: 'scroll-visits-broadcasted',
        title: 'Visits Broadcasted',
        hidePending: false,
        permissionKey: 'view_caregiver_page_visits_broadcasted'
      },
      {
        id: 'scroll-patients',
        title: 'Patients',
        hidePending: false,
        permissionKey: 'view_caregiver_page_visits_broadcasted'
      },
      {
        id: 'scroll-application',
        title: 'Application',
        hidePending: false,
        permissionKey: 'view_caregiver_page_application'
      },
      {
        id: 'scroll-documents',
        title: 'Documents',
        hidePending: false,
        permissionKey: 'view_caregiver_page_documents'
      },
      {
        id: 'scroll-payroll',
        title: 'Payroll',
        hidePending: false,
        permissionKey: 'view_caregiver_page_payroll'
      },
      {
        id: 'scroll-pay-rates',
        title: 'Pay Rates',
        hidePending: false,
        billingFeature: true,
        permissionKey: 'view_caregiver_page_pay_rates'
      },
      {
        id: 'scroll-payroll-tax',
        title: 'Payroll Tax Information',
        hidePending: false,
        billingFeature: true,
        permissionKey: 'view_caregiver_page_payroll_tax'
      },
      {
        id: 'scroll-recent-activity',
        title: 'Recent Activity',
        hidePending: false,
        permissionKey: 'view_caregiver_page_recent_activity'
      }
    ]
  };

  $scope.goToItem = function (item) {
    $scope.navigationItems.active = item.id;
    generalUtils.scrollToElement(item.id);
  };

  $scope.sections = {
    activeIdx: 0,
    list: [
      {
        label: "Calendar",
        activeIdx: 0,
        list: [
          {
            id: "caregiver-main-calendar",
            view: "caregiver-main-calendar.html",
            label: "Calendar",
            permissionKey: "view_caregiver_page_calendar"
          },
          {
            id: "caregiver-main-list-view",
            view: "caregiver-main-list-view.html",
            label: "Assigned visits",
            permissionKey: "view_caregiver_page_assigned_visits"
          },
          {
            id: 'caregiver-main-assigned-tasks',
            view: "caregiver-main-assigned-tasks.html",
            label: 'Assigned Tasks',
            hidePending: true,
            permissionKey: 'view_caregiver_page_assigned_tasks'
          },
          {
            id: "caregiver-main-availability",
            view: "caregiver-main-availability.html",
            label: "Availability",
            permissionKey: "view_caregiver_page_availability"
          },
          {
            id: "caregiver-main-attendance",
            view: "caregiver-main-attendance.html",
            label: "Attendance",
            permissionKey: "view_caregiver_page_attendance"
          },
          {
            id: "caregiver-main-visits-broadcasted",
            view: "caregiver-main-visits-broadcasted.html",
            label: "Visits Broadcasted",
            permissionKey: "view_caregiver_page_visits_broadcasted"
          },
          {
            id: "caregiver-main-patient-list",
            view: "caregiver-main-patient-list.html",
            label: "Patients",
            permissionKey: "view_caregiver_page_visits_broadcasted"
          }
        ]
      },
      {
        label: "Profile",
        activeIdx: 0,
        list: [
          {
            id: "caregiver-main-about",
            view: "caregiver-main-about.html",
            label: "About",
            permissionKey: "view_caregiver_page_about"
          },
          {
            id: "caregiver-main-status-review",
            view: "caregiver-main-status-review.html",
            label: "Status review",
            permissionKey: "view_caregiver_page_status_review"
          },
          {
            id: "caregiver-main-staffing-preferences",
            view: "caregiver-main-staffing-preferences.html",
            label: "Staffing Preferences",
            permissionKey: "view_caregiver_page_emergency"
          },
          {
            id: "caregiver-main-emergency-contact",
            view: "caregiver-main-emergency-contact.html",
            label: "Emergency Contact",
            permissionKey: "view_caregiver_page_emergency"
          }
        ]
      },
      {
        label: "Compliance",
        activeIdx: 0,
        list: [
          {
            id: "caregiver-main-compliance",
            view: "caregiver-main-compliance.html",
            label: "Compliance",
            permissionKey: "view_caregiver_page_compliance"
          },
          {
            id: "caregiver-main-training-center",
            view: "caregiver-main-training-center.html",
            label: "Training Center",
            permissionKey: "view_caregiver_page_training_center"
          },
          {
            id: "caregiver-main-application",
            view: "caregiver-main-application.html",
            label: "Application",
            permissionKey: "view_caregiver_page_application"
          }
        ]
      },
      {
        label: "Documents",
        activeIdx: 0,
        list: [
          {
            id: "caregiver-main-documents",
            view: "caregiver-main-documents.html",
            label: "Documents",
            permissionKey: "view_caregiver_page_documents"
          }
        ]
      },
      {
        label: "Payroll",
        activeIdx: 0,
        list: [
          {
            id: "caregiver-main-payroll",
            view: "caregiver-main-payroll.html",
            label: "Payroll",
            permissionKey: "view_caregiver_page_payroll"
          }
        ]
      },
      {
        label: "Administrative",
        activeIdx: 0,
        list: [
          {
            id: "caregiver-main-pay-rates",
            view: "caregiver-main-pay-rates.html",
            label: "Pay Rates",
            permissionKey: "view_caregiver_page_pay_rates",
            isBillingFeature: true
          },
          {
            id: "caregiver-main-payroll-tax-information",
            view: "caregiver-main-payroll-tax-information.html",
            label: "Payroll Tax Information",
            permissionKey: "view_caregiver_page_payroll_tax",
            isBillingFeature: true
          },
          {
            id: "caregiver-main-recent-activity",
            view: "caregiver-main-recent-activity.html",
            label: "Recent Activity",
            permissionKey: "view_caregiver_page_recent_activity"
          }
        ]
      },
    ]
  };

  $scope.handleClickSectionMenu = (section) => {
    $scope.sections.activeIdx = $scope.sections.list.indexOf(section);
    $scope.sections.list[$scope.sections.activeIdx].activeIdx = 0;
    document.querySelector(".caregiver-page").scrollTop = 0;
    $timeout(autoTrackSubsection, 3000);
  }

  function autoTrackSubsection() {
    const parent = document.querySelector(".caregiver-page");

    if (parent === null) {
        return;
    }

    const sections = document.querySelectorAll(".caregiver-page > *");
    const sectionNames = [...sections].map(x => x.dataset.subsection);
    const initialOffset = parent.offsetTop;

    const sectionFromTo = {};

    sections.forEach(child => {
        sectionFromTo[child.dataset.subsection] = {
            from: child.offsetTop - initialOffset,
            to: child.offsetTop - initialOffset + child.scrollHeight
        }
    })

    parent.onscroll = () => {
        if ($scope.isManuallyScrolling) {
            return;
        }

        for (const [subsection, { from, to }] of Object.entries(sectionFromTo)) {
            if (parent.scrollTop >= from && parent.scrollTop < to) {
                const currentSection = $scope.sections.list[$scope.sections.activeIdx];
                const activeIdx = sectionNames.indexOf(subsection);

                if (currentSection.activeIdx !== activeIdx) {
                    currentSection.activeIdx = activeIdx;
                    $scope.$digest();
                }
            }
        }
    }
  }

  // I'm not proud of it, but that's the only way I could make it work in angularjs 🤦‍♂️
  [1, 2, 3, 4].forEach((x) => $timeout(autoTrackSubsection, x * 1000));

  $scope.handleClickSubsectionMenu = (subsectionIdx) => {
      $scope.isManuallyScrolling = true;

      $timeout(() => $scope.isManuallyScrolling = false, 1000);

      const activeSection = $scope.sections.list[$scope.sections.activeIdx];
      activeSection.activeIdx = subsectionIdx;

      const activeSubsection = activeSection.list[activeSection.activeIdx];

      const id = `caregiver-main-section-${activeSubsection.id}`;
      generalUtils.scrollToElement(id);
  }

  $scope.getStatusByValue = function (statusValue) {
    return $scope.statuses.find(function (stat) {
      return stat.value === statusValue;
    });
  }

  $scope.getStatusTextByValue = (statusValue) => {
    const status = $scope.getStatusByValue(statusValue);
    return status ? status.text : "";
  }

  $scope.docBNotNeeded = [
    'U.S. Passport or U.S. Passport Card',
    'Permanent Resident Card or Alien Registration Receipt Card',
    'Employment Authorization Document Card',
    'Foreign passport with Form I-94 or Form I-94A with Arrival-Departure Record, and containing an endorsement to work',
    'Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A',
    'Foreign passport containing a Form I-551 stamp or Form I-551 printed notation'
  ];
  var customComplianceItems = $scope.customComplianceItems = [
    {
      showSelect: false,
      name: 'I-9',
      sectionLabel: 'General',
      docA: true,
      docB: true,
      expires: true,
      comments: true,
      options: {
        docA: [
          {
            name: 'U.S. Passport or U.S. Passport Card',
            needsB: false
          }, {
            name: 'Permanent Resident Card or Alien Registration Receipt Card',
            needsB: false
          }, {
            name: 'Employment Authorization Document Card',
            needsB: false
          }, {
            name: 'Foreign passport with Form I-94 or Form I-94A with Arrival-Departure Record, and containing an endorsement to work',
            needsB: false
          }, {
            name: 'Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A',
            needsB: false
          }, {
            name: 'Foreign passport containing a Form I-551 stamp or Form I-551 printed notation',
            needsB: false
          }, {
            name: 'Driver\'s License',
            needsB: true
          }, {
            name: 'ID Card',
            needsB: true
          }
        ], docB: [
          {
            name: 'U.S. Social Security account number card that is unrestricted'
          }, {
            name: 'Consular Report of Birth Abroad'
          }, {
            name: 'Certification of Birth Abroad issued by the U.S. Department of State'
          }, {
            name: 'Certification of Report of Birth issued by the U.S. Department of State'
          }, {
            name: 'Original or certified copy of a birth certificate issued by a state, county, municipal authority or outlying territory of the United States bearing an official seal'
          }, {
            name: 'Native American tribal document'
          }, {
            name: 'U.S. Citizen ID Card'
          }, {
            name: 'Identification Card for Use of Resident Citizen in the United States'
          }, {
            name: 'Employment authorization document issued by the Department of Homeland Security'
          }
        ]
      }
    },
    {
      showSelect: false,
      name: 'References',
      sectionLabel: 'General',
      reference1: true,
      reference2: true,
      degree: true, ////
      comments: true
    },
    {
      showSelect: false,
      name: 'Criminal background',
      sectionLabel: 'General',
      sentOutDate: true,
      receivedDate: true,
      results: true,
      comments: true,
      options: {
        results: ['Submitted', 'ok', 'Temporary Denial', 'Final Denial', 'Resubmitted']
      }
    },
    {
      showSelect: false,
      name: 'Last employment agency',
      sectionLabel: 'General',
      lastEmployment: true, ////
      startDate: true, /////
      endDate: true, /////
      results: true,
      comments: true
    },
    {
      showSelect: false,
      name: 'Training School',
      sectionLabel: 'General',
      trainingSchool: true, ////
      certificationDate: true, /////
      instructor: true, /////
      verificationDate: true, ////
      onFile: true, /////
      comments: true
    },
    {
      name: 'Face mask Provided (2017)',
      sectionLabel: 'Medical',
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'Flu Vaccine (2017)',
      sectionLabel: 'Medical',
      results: true,
      comments: true,
      options: {
        results: ['Completed (In-Office)',
          'Completed (Outside of off',
          'Exempt',
          'Declined']
      }
    }, {
      name: 'Annual health Assessment',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'Chest X- Ray',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Positive',
          'Negative']
      }

    }, {
      name: 'Drug Screen',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Positive',
          'Negative']
      }
    }, {
      name: 'Flu Shot',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'Flu Vaccination',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Accepted',
          'Declined']
      }
    }, {
      name: 'N1H1 Vaccination',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Accepted',
          'Declined']
      }
    }, {
      name: 'PPD',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Positive',
          'Negative']
      }
    }, {
      name: 'Pre employment physical',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'Quantiferon',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Positive',
          'Negative']
      }
    }, {
      name: 'Rubella Immune',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Immune',
          'Not Immune']
      }
    }, {
      name: 'Rubella (MMR)',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'Rubeola Immune',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Immune',
          'Not Immune']
      }
    }, {
      name: 'Rubeola (MMR1)',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'Rubeola (MMR2)',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Complete', 'Incomplete']
      }
    }, {
      name: 'TB - Screen',
      sectionLabel: 'Medical',
      expires: true,
      results: true,
      comments: true,
      options: {
        results: ['Positive',
          'Negative']
      }
    }, {
      name: '3 month evaluation',
      sectionLabel: 'Evaluations, Orientation, In-Service',
      date: true,
      expires: true,
      score: true,
      comments: true
    }, {
      name: '6 month evaluation',
      sectionLabel: 'Evaluations, Orientation, In-Service',
      date: true,
      expires: true,
      score: true,
      comments: true
    }, {
      name: 'Annual Evaluation',
      sectionLabel: 'Evaluations, Orientation, In-Service',
      date: true,
      expires: true,
      score: true,
      comments: true
    }, {
      name: 'Orientation',
      sectionLabel: 'Evaluations, Orientation, In-Service',
      date: true,
      expires: true,
      score: true,
      comments: true
    }, {
      name: 'Supervisory Visit',
      sectionLabel: 'Evaluations, Orientation, In-Service',
      date: true,
      expires: true,
      score: true,
      comments: true
    }

  ];

  var customComplianceItemsMap = {};
  customComplianceItems.forEach(function (item) {
    customComplianceItemsMap[item.name] = item.options;
  });

  $scope.formsList = {};

  $scope.complianceItemSettings = [
    'comments',
    'date',
    'sentOutDate',
    'receivedDate',
    'completionDate',
    'results',
    'expires',
    'score',
    'docA',
    'docB',
    'reference1',
    'reference2',
    'trainingSchool',
    'instructor',
    'degree',
    'lastEmployment',
    'startDate',
    'endDate',
    'certificationDate',
    'verificationDate',
    'onFile',
    'removedAt'
  ];

  $scope.onFile = [
    { value: 'YES', text: 'YES' },
    { value: 'NO', text: 'NO' }
  ];

  $scope.sideBarState = undefined;

  $scope.handleSideBarStateChange = (newState, calendarDay = undefined) => {
    if ($scope.sideBarState === newState) {
      $scope.sideBarState = undefined;
    } else {
      switch (newState) {
        case "NOTES":
          setNotesData("Caregiver");
          break;
        case "CALENDAR":
          setNotesData('CaregiverCalendar', calendarDay);
          break;
        default:
          break;
      }

      $scope.sideBarState = newState;
    }
  }

  $scope.handleOpenCalendarNote = ({ date }) => {
      $scope.handleSideBarStateChange('CALENDAR', date);
  }

  var caregiversMap = DatabaseApi.caregivers();

  var getCaregiverNameById = function (id) {
    if (!caregiversMap) return '';
    var c = caregiversMap[id.toString()];
    if (c) {
      return c.firstName + ' ' + c.lastName;
    }
    return '';
  };

  $scope.getFileUrl = function (id, cb) {
    DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/document_file/' + id).then(function (res) {
      if (cb) cb(res.data.url);
      else $window.open(res.data.url);
    }, function (err) {
      if (cb) cb('');
      else toaster.pop('warning', 'something went wrong', '');
    });
  };

  $scope.downloadFileUpload = (id) => {
    DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/document_file/' + id).then(function (res) {
      try {
        const fileName = res.data.url.split('/')[4].split('?')[0];
        $http.get(res.data.url, { responseType: 'arraybuffer' })
          .then(response => {
            const file = new Blob([(response.data)], { type: 'application/pdf' });
            download(file, fileName, "application/pdf");
          })
          .catch(() => toaster.pop('warning', 'something went wrong', ''));
      } catch (e) {
        toaster.pop('warning', 'something went wrong', '');
      }
    }, function (err) {
      toaster.pop('warning', 'something went wrong', '');
    });
  }

  $scope.removeFileUpload = (uploadId) => {
    DatabaseApi.delete('hr/agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/documents/' + uploadId).then(() => {
      $scope.getCaregiverUploadedDocuments(false);
      toaster.pop("success", 'Removed successfully')
    }, (_err) => {
      toaster.pop('warning', 'something went wrong', '');
    });
  }

  $scope.sendPDFUploadToEmailOrFax = (id, type) => {
    DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/document_file/' + id).then((res) => {
      $http.get(res.data.url, { responseType: 'arraybuffer' })
        .then(response => {
          const file = new Blob([(response.data)], { type: 'application/pdf' });
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            const newScope = $scope.$new();
            newScope.caregiverId = proId;
            newScope.type = type;
            newScope.encodedPdf = reader.result;

            $uibModal.open({
              templateUrl: 'admin/views/caregiver-document-send-pdf-modal.html',
              size: 'md',
              scope: newScope,
              controller: 'caregiverDocumentSendPdfModalCtrl'
            });
          };
        })
    }, function (err) {
      toaster.pop('warning', 'something went wrong', '');
    });
  }

  // $scope.getCaregiverComplianceItemFileUpload = function (uploadedFile) {
  //   uploadedFile.isLoading = true;
  //   DatabaseApi.get('agencies/' + $rootScope.agencyId + '/caregivers/' + proId +
  //     '/document_uploads/' + uploadedFile.id + '/generate_url').then(function (res) {
  //       $window.open(res.data.signedUrl);
  //     }, function (err) {
  //       toaster.pop('warning', 'something went wrong', '');
  //     }).finally(function () {
  //       uploadedFile.isLoading = false;
  //     });
  // }

  var files = {
    types: [],
    list: []
  };
  var docTypesCache = [];

  $scope.getStageById = function (stageId) {
    if (!stageId || !$scope.selectedApplicationCertification || !$scope.stages[$scope.selectedApplicationCertification]) {
      return { labelColor: '#1c84c6' };
    }
    return $scope.stages[$scope.selectedApplicationCertification].find(stage => stage.id === stageId);
  }

  $scope.getProgressbarShrinkerPercentageWidth = function (percentage) {
    var val = 100 - percentage;
    return val + '%';
  }

  $scope.applicationByCaregiverCertifications = {};

  // get caregiver answers
  function getCaregiverApplicationAnswers(updatedStageId) {
    const applicationCertification = $scope.profile.certifications.find(cert => cert === $scope.selectedApplicationCertification);

    if (applicationCertification === undefined) {
      return toaster.pop('error', "Couldn't get desired application");
    }

    if ($scope.applicationByCaregiverCertifications[applicationCertification]) {
      if (updatedStageId) {
        $scope.applicationByCaregiverCertifications[$scope.selectedApplicationCertification] = {
          answers: $scope.applicationByCaregiverCertifications[$scope.selectedApplicationCertification].answers,
          caregiverSectionStageId: updatedStageId,
          caregiverSectionStageLabel: $scope.stages[$scope.selectedApplicationCertification].find(stage => stage.id === updatedStageId).label
        };
      }
      initCaregiverApplication();
      return;
    }

    $scope.isLoadingCaregiverApplication = true;
    const url = wildcard(
      "hr/agencies/:agencyId/caregivers/:caregiverId/certifications/:certification/caregiver_application_answers",
      $rootScope.agencyId,
      proId,
      applicationCertification
    );
    DatabaseApi.get(url).then((res) => {
      $scope.applicationByCaregiverCertifications[$scope.selectedApplicationCertification] = {
        answers: res.data.sections,
        caregiverSectionStageId: res.data.caregiverSectionStageId,
        caregiverSectionStageLabel: res.data.caregiverSectionStageLabel,
      };

      initCaregiverApplication();
    }, () => {
      toaster.pop('error', 'Failed to load caregiver application');
    }).finally(() => {
      $scope.isLoadingCaregiverApplication = false;
    });
  }

  function initCaregiverApplication() {
    const applicationResult = $scope.applicationByCaregiverCertifications[$scope.selectedApplicationCertification];
    $scope.answers = applicationResult.answers;
    $scope.caregiverSectionStageId = applicationResult.caregiverSectionStageId;
    $scope.caregiverSectionStageLabel = applicationResult.caregiverSectionStageLabel;

    const duplicatedVersionsSubsections = [
      ["Form W-4 (old)", "Form W-4 2020", "Form W-4 2021", "Form W-4 2022"],
      ["IT- 2104 Employee's withholding allowance certificate", "IT- 2104 Employee's withholding allowance certificate (2021)",
        "IT- 2104 Employee's withholding allowance certificate (2022)"],
      ["8850 Pre-Screening notice and certification request for the work opportunity credit", "8850 Pre-Screening notice and certification request for the work opportunity credit (2020)"],
    ];
    duplicatedVersionsSubsections.forEach(duplicatedVersionsArr => {
      removeIrrelevantVersionIfNeeded($scope.answers, duplicatedVersionsArr);
    });

    const isAnsweredArray = answeredAnyQuestion();
    $scope.isAnswered = (isAnsweredArray.length !== 0);
    setCaregiverFiles();

    // getCaregiverDocs();
    setShowEditStage();
  }

  function removeIrrelevantVersionIfNeeded(sections, versionsNames) {
    let toRemove = [];

    for (let i = 0; i < sections.length; i++) {
      for (let j = versionsNames.length - 1; j >= 0; j--) {
        const subsection = sections[i].subSections.find(s => s.data && s.data.title === versionsNames[j]);
        if (subsection && hasAnyAsnwers(subsection)) {
          toRemove = versionsNames.filter(v => v !== versionsNames[j]);
          break;
        }
      }
    }

    if (toRemove.length === 0) {
      return;
    }

    sections.forEach(section => {
      section.subSections = section.subSections.filter(subsection => !toRemove.includes(subsection.data.title))
    });
  }

  function hasAnyAsnwers(subsection) {
    for (const questionGroup of subsection.questionGroups) {
      for (const question of questionGroup.questions) {
        if (question.answer || question.chosenAnswerId) {
          return true;
        }
      }
    }
    return false;
  }

  function answeredAnyQuestion() {
    var arrayOfQuestions = [];
    var finishedSubSections;
    $scope.hasAnyCompletedButNotGeneratedSubsection = false;
    $scope.answers.forEach(function (section) {
      finishedSubSections = 0;
      section.isDone = false;
      section.subsectionCount = 0;
      section.subsectionsDoneButNotGenerated = 0;
      section.subSections.forEach(function (subsection) {
        subsection.isDone = false;
        subsection.qgCount = 0;
        if (subsection.questionGroups.length > 0 && (!subsection.document || !subsection.document.isDocumentGenerated)) {
          subsection.questionGroups.forEach(function (qg) {
            qg.isDone = false;
            qg.questionCount = 0;
            qg.questions.filter(function (d) { return d.answer != null || d.chosenAnswerId != null || d.chosenAnswerIds != null }).forEach(function (question) {
              arrayOfQuestions.push(question);
            });
            qg.questions.forEach(function (d) {
              if (!d.isMandatory || (d.isMandatory && (d.answer != null || d.chosenAnswerId != null || d.chosenAnswerIds != null || d.isHidden))) {
                qg.questionCount++;
              }
            });
            if (qg.questionCount === qg.questions.length) {
              qg.isDone = true;
              subsection.qgCount++;
            }
          });

        } else {
          subsection.isDone = true;
          finishedSubSections++;
        }

        if (subsection.qgCount === subsection.questionGroups.length) {
          if (!subsection.isDone) {
            if (subsection.document) {
              if (subsection.document.isDocumentGenerated) {
                subsection.isDone = true;
              } else {
                subsection.isDone = false;
                subsection.doneButNotGenerated = true;
                section.subsectionsDoneButNotGenerated++;
                $scope.hasAnyCompletedButNotGeneratedSubsection = true;
              }
            } else {
              subsection.isDone = true;
            }
            finishedSubSections++;
          }
          section.subsectionCount++;
        }

      });
      section.progressPercentage = Math.round((finishedSubSections / section.subSections.length) * 100);

      if (section.subsectionCount === section.subSections.length) {
        section.isDone = true;
      }

    });



    return arrayOfQuestions;
  }

  // get caregiver viewed visits
  DatabaseApi.get('agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/visit_engagements').then(function (res) {
    res.data.engagements.forEach(function (p) {
      if (p.visit.assignedCaregiver) {
        p.assignedToName = getCaregiverNameById(p.visit.assignedCaregiver);
      } else {
        p.assignedToName = '';
      }
      p.broadcastedAt = p.visit.createdAt;
      p.visit.status = VisitStatus.getVisitStatus(p.visit);
    });
    //$scope.engagements = res.data.engagements;
    if (res.data.engagements.length) initEngagementTable(res.data.engagements);

  }, function (err) {
    //$scope.engagements = [];
  });


  $scope.setApplicationPendingStage = function (stageId) {
    SweetAlert.swal({
      title: "Appplication Stage",
      text: "Are you sure you want to update caregiver Appplication Stage?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, update stage",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        const url = wildcard(
          "hr/agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/certifications/:certification/set_hr_stage",
          $rootScope.agencyId,
          $rootScope.agencyMemberId,
          $scope.profile.id,
          $scope.selectedApplicationCertification
        );
        const body = {
          HRSectionStageId: stageId
        }
        DatabaseApi.patch(url, body).then((res) => {
          getCaregiverApplicationAnswers(stageId);
          toaster.pop('success', 'Successfully updated caregiver application stage ');
        }, (err) => {
          toaster.pop('error', 'failed to update hr stage');
        });
      }
    });
  }

  // function getCaregiverDocumentTypes(certType) {
  //   // get caregivers document types
  //   DatabaseApi.get(
  //     "hr/agencies/" +
  //     $rootScope.agencyId +
  //     "/certification/" +
  //     certType +
  //     "/document_types"
  //   ).then(
  //     function (res) {
  //       files.types = res.data.documentTypes;
  //       docTypesCache = [];
  //       res.data.documentTypes.forEach(function (d) {
  //         docTypesCache.push(d);
  //       });
  //       setCaregiverFiles();
  //     },
  //     function (err) { }
  //   );
  // }

  function getCaregiverDocs() {
    // get caregivers docs
    DatabaseApi.get(
      "hr/agencies/" +
      $rootScope.agencyId +
      "/caregivers/" +
      proId +
      "/documents"
    ).then(
      function (res) {
        allDocs = res.data.documents;
        files.list = res.data.documents;
      },
      function (err) { }
    );
  }

  /*
  id: HRDocumentId;
    documentType: HRDocumentTypeId;
    createdAt: Instant;
    createdBy: AgencyMemberId;
    expirationDate: Instant;
    isGenerated: boolean;
    */



  function getHourByNumber(number) {
    if (number < 12) return number + 'AM';
    else if (number === 12) return number + 'PM';
    else return (number - 12) + 'PM';
  }

  $scope.getFormatedAnswer = function (answer) {
    //console.log('dsvd');
    //console.log(answer);
    //Text: "Text",
    //Address: "Address",
    //Email: "Email",
    //Numeric: "Numeric",
    //Dollar: "Dollar",
    //PhoneNumber: "PhoneNumber",
    //SSN: "SSN",
    //FullDate: "FullDate",
    //MonthYearDate: "MonthYearDate",
    //YearDate: "YearDate",
    //Radio: "Radio",
    //DropDown: "DropDown",
    //Checkbox: "Checkbox",
    //Signature: "Signature",
    //StartAndEndTime: "StartAndEndTime",
    //Star: "Star",
    //Placeholder: "Placeholder"

    var result = '';

    if (answer.type === 'Signature' ||
      answer.type === 'Placeholder' ||
      (!answer.answer && !answer.chosenAnswerId && !answer.chosenAnswerIds)
    ) {
      result = 'No answer';

    } else if (answer.type === 'Text' || answer.type === 'Address' || answer.type === 'Email' || answer.type === 'Numeric' || answer.type === 'Dollar' || answer.type === 'SSN' || answer.type === 'DownloadableFile') {
      result = answer.answer;

    } else if (answer.type === 'Checkbox') {
      var answers = '';
      var answersArr = answer.answer ? JSON.parse(answer.answer) : [];
      if (!answersArr.length) {
        answersArr = answer.chosenAnswerIds ? answer.chosenAnswerIds : [];
      }
      answersArr.forEach(function (a, i) {
        answersArr[i] = a.toString();
      });
      answer.possibleAnswers.forEach(function (option) {
        if (answersArr.indexOf(option.id.toString()) > -1) {
          if (answers.length > 0) answers += ', ';
          answers += option.text;
        }
      });
      result = answers;

    } else if (answer.type === 'DropDown' || answer.type === 'Radio' || answer.type === 'AmericanTest') {
      var answerId = answer.chosenAnswerId;
      var answers = '';
      answer.possibleAnswers.forEach(function (option) {
        if (option.id === answerId) {
          answers += option.text;
          if (answer.type === 'AmericanTest' && answerId === 1) {
            answers += ' (correct)';
          } else if (answer.type === 'AmericanTest') {
            answers += ' (incorrect)';
          }
        }
      });
      result = answers;

    } else if (answer.type === 'FullDate' || answer.type === 'BirthDate') {
      result = answer.answer ? moment(answer.answer).format('MM/DD/YYYY') : '';

    } else if (answer.type === 'MonthYearDate') {
      result = answer.answer ? moment(answer.answer).format('MMM YYYY') : '';

    } else if (answer.type === 'YearDate') {
      result = answer.answer ? moment(answer.answer).format('YYYY') : '';

    } else if (answer.type === 'Star') {
      result = answer.answer ? answer.answer + '/5' : '';

    } else if (answer.type === 'StartAndEndTime') {
      var ans = JSON.parse(answer.answer);
      result = 'Start: ' + getHourByNumber(ans.startTime) + ', End: ' + getHourByNumber(ans.endTime);
    } else if (answer.type === 'PhoneNumber') {
      var text = answer.answer.substring(2);
      result = $filter('americanphone')(text);
    }

    //console.log(result);
    return result;
  }

  $scope.showUnblockedBtn = false;
  function getShowUnblockLoginBtn() {
    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/caregivers/' + proId + '/show_unblock_login').then(function (response) {
      $scope.showUnblockedBtn = response.data.show;
    }, function (response) {

    });
  }
  getShowUnblockLoginBtn();

  $scope.unblockCaregiverLogin = function () {
    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/caregivers/' + proId + '/unblock_login').then(function (response) {
      $scope.showUnblockedBtn = false;
      toaster.pop('success', 'Caregiver unblocked', '');
    }, function (response) {
      toaster.pop('error', 'Something went wrong', '');
    });
  }

  function setNextStatusAttributes(nextStatus, effectiveDate) {
      $scope.profile.nextStatus = nextStatus;
      $scope.profile.nextStatusEffectiveDate = effectiveDate;
      
      const effectiveDateAsMoment = moment(effectiveDate);
      const ymdEffectiveDate = effectiveDateAsMoment.format("MM/DD/YYYY");

      let nextStatusText = null;
      let nextStatusClass = null;

      if (nextStatus !== undefined && nextStatus !== null) {
        const statusDetails = $scope.statuses.find((status) => status.value == nextStatus);
        nextStatusClass = statusDetails.statusClass;
        nextStatusText = `Will be ${statusDetails.text} on ${ymdEffectiveDate}`;
      }
      
      $scope.profile.nextStatusText = nextStatusText;
      $scope.profile.nextStatusClass = nextStatusClass;
  }

  $scope.onStatusChanges = ({ newStatus, effectiveDate, setEffectiveDateAsHireDate }) => {
        const oldStatus = $scope.profile.status;
        const effectiveDateAsMoment = moment(effectiveDate);
        const ymdEffectiveDate = effectiveDateAsMoment.format("YYYY-MM-DD");

        if (effectiveDateAsMoment.isSameOrBefore(moment())) {
          $scope.profile.status = newStatus;
          $scope.profile.statusClass = $scope.statuses.find((x) => x.value === newStatus)?.statusClass ?? null;

          // If there's a future status, and the user rewrote it with a past status, we should remove the nextStatus indication 
          // from the profile.
          setNextStatusAttributes(null, null);
        }
        else {
          if (!$scope.profile.nextStatusEffectiveDate || 
              effectiveDateAsMoment.isSameOrBefore(moment($scope.profile.nextStatusEffectiveDate))) {
                setNextStatusAttributes(newStatus, effectiveDate);
            }
        }
        
        $scope.$broadcast("refresh_visits");
        CaregiverModalService.getCaregiverVisits($scope.caregiverId).then(
          (visits) => ($scope.visits = visits)
        );

        if (newStatus === "TERMINATED") {
          $scope.profile.terminationDate = ymdEffectiveDate;
        }

        if (oldStatus === "TERMINATED" && newStatus === "ACTIVE") {
            $scope.profile.rehireDate = ymdEffectiveDate;
            $scope.updateHireDate({ rehire: true });
        }

        if(setEffectiveDateAsHireDate) {
          $scope.profile.hireDate = ymdEffectiveDate;
        }

        initCaregiverStatusHistoryTable();
  }

  function setCaregiverFiles() {
    if (!files.types || !files.list || !$scope.answers || !allDocs) {
      // console.log('ddddd');
      $timeout(setCaregiverFiles, 300);
      return;
    }

    var docsMap = {};


    allDocs.forEach(function (doc) {
      if (doc.subsection) {
        if (!docsMap[doc.subsection.toString()]) docsMap[doc.subsection.toString()] = [];
        docsMap[doc.subsection.toString()].push(doc);
      }
    });

    $scope.answers.forEach(function (section) {

      if (section.subSections) {
        section.subSections.forEach(function (subsection) {
          if (subsection.document !== null) {
            subsection.isForm = true;
            if (docsMap[subsection.data.id.toString()])
              subsection.uploads = docsMap[subsection.data.id.toString()];
          }

          if (subsection.questionGroups) {
            subsection.questionGroups.forEach(function (group) {

              if (group.questions) {
                group.questions.forEach(function (question) {
                  if (question.type === 'Signature' || question.type === 'FreeText') {
                    // console.log(subsection);
                    subsection.isForm = true;
                  }
                });
              }
            });
          }
        });
      }
    });

    // console.log($scope.answers);

    if (docTypesCache.length > 0) {
      files.types = docTypesCache.sort(function (a, b) { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; });
    } else {
      files.types = docTypesCache;
    }

    var typesMap = {};
    if (files && files.types) {
      files.types.forEach(function (type, i) {
        //if(type.documentType) {
        typesMap[type.id.toString()] = i;
        type.uploads = [];
        //}
      });
    }

    if (files && files.list) {
      files.list.forEach(function (doc) {
        if (doc.documentType) {
          if (files.types[typesMap[doc.documentType.toString()]] &&
            files.types[typesMap[doc.documentType.toString()]].uploads) {
            files.types[typesMap[doc.documentType.toString()]].uploads.push(doc);
          }
        }
      });
    }

    if (files && files.types) {
      files.types.forEach(function (type) {
        if (type.uploads.length > 0) {
          type.uploads.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt)
          });
        }
      });
    }

    $scope.showAllFiles = function (file) {
      if ($scope.numberOfFilesToShow === 1) {
        $scope.numberOfFilesToShow = file.uploads.length;
      } else {
        $scope.numberOfFilesToShow = 1;
      }
    }

    $scope.files = files.types;

    if ($scope.activeRowApplication) {
      if ($scope.activeRowApplication.htmlType === 'sections') {
        $scope.answers.forEach(function (section) {

          if (section.data.id === $scope.activeRowApplication.data.id) {
            $scope.activeRowApplication = Object.assign($scope.activeRowApplication, section);
          }

        });
      } else if ($scope.activeRowApplication.htmlType === 'documents') {
        $scope.files.forEach(function (file) {
          if (file.id === $scope.activeRowApplication.id) {
            $scope.activeRowApplication = Object.assign($scope.activeRowApplication, file);
            console.log($scope.activeRowApplication);
          }
        });
      }

    }

    $scope.answers.forEach(section => {
      section.subSections.forEach(subsection => {
        if (subsection.uploads) {
          subsection.showCompleteCori = subsection.uploads.find(upload => upload.documentType === 105);
          subsection.showCompleteI9 = subsection.uploads.find(upload => upload.documentType === 7);
          subsection.showComplete8850 = subsection.uploads.find(upload => upload.documentType === 3);
          subsection.showCompleteForm = subsection.uploads.find(upload => $scope.notCompletedByDefaultFormDocumentIds.includes(upload.documentType));
        }
      });
    });
  }

  $scope.seeFileExample = function (url) {
    $window.open(url);
  };

  $scope.generateExamplePdf = function (sectionId, subsectionId) {

    var url = 'hr/agencies/' + $rootScope.agencyId + '/subsection/' + subsectionId + '/generate_doc_preview';
    DatabaseApi.post(url).then(function (res) {

      $scope.answers.forEach(function (section) {
        if (section.data.id !== sectionId) return;
        section.subSections.forEach(function (subsection) {
          if (subsection.data.id = subsectionId) subsection.loading = false;
        });
      });

      if (res.data.url) $window.open(res.data.url);

    }, function (err) {

    });

  };

  $scope.generateSubsection = function (subsection) {
    var url =
      "hr/caregivers/" +
      $scope.caregiverId +
      "/subsection/" +
      subsection.data.id +
      "/generate_subsection";
    DatabaseApi.post(url).then(
      function (res) {
        getCaregiverDocs();
        initCaregiverApplication();
        $scope.getCaregiverUploadedDocuments(true);
        const applicationCertification = $scope.profile.certifications.find(
          (cert) => cert === $scope.selectedApplicationCertification
        );
        $scope.applicationByCaregiverCertifications[applicationCertification] = null;
        getCaregiverApplicationAnswers();
        subsection.loading = false;
        toaster.pop("success", "Success", "File generated");
      },
      function (err) {
        subsection.loading = false;
        toaster.pop("error", "Something Went Wrong", "Please try again");
      }
    );
  };

  $scope.uploadFile = function ($event, doc) {
    $event.stopPropagation();
    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/upload-multiple-doc-modal.html',
      size: 'md',
      controller: 'uploadMultipleDocModalCtrl',
      resolve: {
        docType: function () { return doc; },
        proId: function () { return proId; }
      }
    });
    modalInstance.result.then(function (res) {
      getCaregiverDocs();
      $scope.getCaregiverUploadedDocuments();
    }, function () {
      getCaregiverDocs();
      $scope.getCaregiverUploadedDocuments();
    });
  };

  $scope.uploadSubsectionFile = function ($event, subsectionId, subsectionName) {

    /*if(!subsectionId){
        var found = null;
        $scope.answers.forEach(function(section)  {
            // if(section.data.title !== 'Documents') return;
            section.subSections.forEach(function(subsection) {
                if(subsection.data.title === subsectionName) {
                    found = subsection.data.id;
                    return;
                }
                if (subsection.questionGroups.length > 0) {
                    subsection.questionGroups.forEach(function(qg)  {
                        qg.questions.forEach(function(d) {
                            if(d.questionText === subsectionName) {
                                found = subsection.data.id;
                            }
                        });

                    });
                }
            });
        });

        if(found){
            subsectionId = found;
        } else {
            toaster.pop('error', 'Please refresh');
            return;
        }
    }
*/
    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/upload-doc-modal.html',
      size: 'md',
      controller: 'uploadSubsectionModalCtrl',
      resolve: {
        subsectionId: function () { return subsectionId; },
        subsectionName: function () { return subsectionName; },
        proId: function () { return proId; }
      }
    });
    modalInstance.result.then(function (res) {
      getCaregiverApplicationAnswers();
    }, function () {
      getCaregiverApplicationAnswers();
    });
  };

  // $scope.uploadComplianceFile = function (caregiverComplianceItemId, caregiverComplianceItemStatusId) {
  //   let newScope = $scope.$new();
  //   newScope.caregiverComplianceItemId = caregiverComplianceItemId;
  //   newScope.caregiverComplianceItemStatusId = caregiverComplianceItemStatusId;

  //   var modalInstance = $uibModal.open({
  //     templateUrl: 'admin/views/upload-doc-modal.html',
  //     size: 'md',
  //     scope: newScope,
  //     controller: 'uploadScanModalCtrl',
  //     resolve: {
  //       proId: function () { return proId; }
  //     }
  //   });
  //   modalInstance.result.then(function (res) {
  //     getComplianceItems();
  //   }, function () {
  //     getComplianceItems();
  //   });
  // };

  $scope.updateHRDocumentExpirationDate = function (documentId, expirationDate) {
    console.log("id", documentId);
    console.log("date", expirationDate);
    if (!documentId || !expirationDate) return;
    var url = 'hr/agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.profile.id + '/documents/' + documentId;

    var body = {
      expirationDate: expirationDate
    };
    DatabaseApi.put(url, body).then(function () {
      toaster.pop('success', "Document Updated");
    }, function () {
      toaster.pop('error', "Something Went Wrong", 'Please try again');
    });
  }

  //$scope.uploadFile({stopPropagation: function (){}}, 1);

  //$scope.uploadFile({id:1});

  $scope.newVisitInstance = function (startDay) {
    console.log("new visit", startDay);
  }

  $scope.addPhone = { val: '', show: false };
  $scope.addPhoneNumber = function () {
    if (!$scope.addPhone.val) return;

    var body = { phoneNumber: '+1' + $scope.addPhone.val };
    var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/phone_number';
    $http.put(url, body).then(function ({ data }) {

      $scope.addPhone = { val: '', show: false };
      let type = 'unknown';
      if (data !== undefined) {
        type = data.phoneNumberSmsable ? 'mobile' : 'landline';
      }
      $scope.profile.phoneNumbers.push({ type: type, phoneNumber: body.phoneNumber });
      toaster.pop('success', 'Success', 'Phone number added');

    }, function (err) {
      toaster.pop('warning', 'something went wrong', err.data.error);
    });
  }

  $scope.removePhoneNumber = function (phoneNumber) {
    // Null-case check.
    if (phoneNumber == null) return;

    // DELETE API url.
    var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/caregivers/' + proId + '/phone_number/' + phoneNumber;

    SweetAlert.swal({
      title: "Remove phone number?",
      text: "Are you sure you want to remove " + phoneNumber + " from your phone numbers?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, remove this phone number",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        $http.delete(url).then(function () {
          $scope.profile.phoneNumbers = $scope.profile.phoneNumbers.filter(function (ph) { return ph.phoneNumber != phoneNumber; });
          toaster.pop('success', 'Successfully removed ' + phoneNumber);
        }, function (err) {
          toaster.pop('warning', 'Could not remove phone number!', err.data.error);
        });
      }
    });
  }

  $scope.showEdit = false;
  if (!pro && proId) {
    const url = "agencies/:agencyId/caregivers/:caregiverId"
      .replace(":agencyId", $rootScope.agencyId)
      .replace(":caregiverId", proId);
    DatabaseApi.get(url).then((res) => {
      $scope.profile = res.data;
      $scope.profile.displayName = `${$scope.profile.firstName} ${$scope.profile.lastName}`;

      setNextStatusAttributes($scope.profile.nextStatus, $scope.profile.nextStatusEffectiveDate);
      setCaregiversStatus();
      start();
    });
  } else {
    $scope.profile = pro;
    start();
  }

  $scope.cal = { birthDate: false };
  $scope.dateOptions = {
    datepickerMode: "year"
  };

  $scope.showApplicationStage = () => {
    if (!$scope.showEditStage) {
      return false;
    }

    if ($rootScope.agencyId === 6205) {
      return ["PENDING", "ACTIVE", "ON_HOLD"].includes($scope.profile.status);
    }

    return $scope.profile.status === "PENDING";
  };

  function setShowEditStage() {
    if (
      $scope.setShowEditStage !== undefined
      || !$scope.stages
      || !$scope.answers
      || !$scope.selectedApplicationCertification
      || !$scope.stages[$scope.selectedApplicationCertification]
    ) {
      $scope.showEditStage = false;
      return;
    }
    $scope.showEditStage = $scope.stages[$scope.selectedApplicationCertification].filter((stage) => {
      return $scope.answers.filter(answer => answer.data.HRSectionStageId === stage.id).length > 0
    }).length > 0;
  }

  function getSectionStages() {
    DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages').then(function (res) {
      $scope.stages = res.data.sectionStages;
      Object.keys($scope.stages).forEach(function (cert) {
        $scope.stages[cert].sort(function (a, b) { return a.order < b.order ? -1 : 1 })
      });
      if ($scope.answers) {
        setShowEditStage();
      }
    }, function (err) {
      toaster.pop('error', 'failed to load hr stages');
    });
  }

  function start() {
    if (!$scope.profile.photoUrl) {
      $scope.profile.photoUrl = 'admin/images/blank-profile.jpg';
    }

    $scope.trainingCenterButtons = [
      {
        title: "Add Missing Orientation",
        show: $scope.profile && $scope.profile.status === "PENDING",
        action: () => {
          OrientationCenterService.addMissingOrientations($scope.caregiverId)
            .then(() =>
              caregiverTrainingCenterAddBundleModalService
                .getBundleAddedEmitter()
                .addBundle()
            )
            .catch(() =>
              toaster.pop(
                "error",
                "Couldn't add orientation",
                "Please try again later"
              )
            );
        },
      },
      {
        title: "Add Bundle",
        show: true,
        action: function () {
          $uibModal.open({
            templateUrl: "admin/views/caregiverTrainingCenterAddBundleModal.html",
            size: "lg",
            controller: "caregiverTrainingCenterAddBundleModalCtrl",
            resolve: {
              caregiverId: function () {
                return $scope.caregiverId;
              },
            },
          });
        },
      },
    ];
    $scope.historyTables = [{ tableName: 'caregiver', recordId: $scope.profile.id }];

    CaregiverModalService.getCaregiverVisits($scope.caregiverId).then(
      (visits) => ($scope.visits = visits)
    );

    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.profile.id + '/availability').then(function (res) {
      $scope.availability = res.data.caregiverAvailability;
    }, function (err) {

    });

    // getComplianceItems();

    getSectionStages();

    getCaregiverDocs();

    $scope.getCaregiverUploadedDocuments = (init = false) => {
      $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/caregivers/${$scope.profile.id}/uploaded_documents`)
        .then(function (res) {
          $scope.caregiverUploadedDocuments = res.data.documents.map(doc => {
            doc.uploads.forEach(upload => {
              upload.selected = init ? false : !!upload.selected;
            });
            doc.category = generalUtils.capitalize(doc.category.split("-")[0])
            return doc;
          });
          initUploadedDocumentsTable($scope.caregiverUploadedDocuments, init);
        });
    };

    $scope.getCaregiverUploadedDocuments(true);

    const status = $scope.statuses.find(s => s.value === $scope.profile.status);
    if (status) {
      $scope.profile.statusClass = status.statusClass;
      $scope.profile.selectedStatusModel = { id: status.id };
    } else {
      $scope.profile.statusClass = 'default';
      $scope.profile.selectedStatusModel = {};
    }

    $scope.profile.offices = $scope.profile.officeIds.map(function (officeId) {
      return $scope.offices.find(function (office) {
        return office.id === officeId;
      });
    });

    $scope.profile.branches = $scope.profile.branchIds.map((branchId) => {
      return $scope.agencyBranches.find(function (branch) {
        return branch.id === branchId;
      });
    }).filter(branch => branch !== undefined);

    const mapCaregiverCertifications = () => {
      let inactiveCaregiverCertificationsAmount = 0;
      $scope.profile.certificationsModel = $scope.profile.certifications.map(cert => {
        let findAgencyCertification = $scope.agencyCertifications.find(obj => obj.label === cert);
        if (findAgencyCertification === undefined) {
          findAgencyCertification = {
            id: -1 - inactiveCaregiverCertificationsAmount,
            label: cert
          };
          inactiveCaregiverCertificationsAmount++;
        }
        return findAgencyCertification;
      }).filter(cert => cert.id >= 0);

      $scope.certificationsTranslationTexts = {
        dynamicButtonTextSuffix: "checked"
      };
      if ($scope.profile.certifications.length > $scope.profile.certificationsModel.length) {
        $scope.certificationsTranslationTexts.dynamicButtonTextSuffix = "checked (from active certifications)";
      }
    }

    const initAgencyMembersNames = () => {
      $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
    }

    const initActivityDetails = () => {
      if ($scope.profile) {
        if ($scope.profile.agencyAssocCreatedByAgencyMemberId) {
          initAgencyMembersNames();
          $scope.profile.createdByName = $scope.agencyMembersNames[$scope.profile.agencyAssocCreatedByAgencyMemberId];
        }
        else if ($scope.profile.agencyAssocCreatedByCaregiverId) {
          $scope.profile.createdByName = getCaregiverNameById($scope.profile.agencyAssocCreatedByCaregiverId)
        }
        if ($scope.profile.agencyAssocUpdatedByAgencyMemberId) {
          initAgencyMembersNames();
          $scope.profile.updatedByName = $scope.agencyMembersNames[$scope.profile.agencyAssocUpdatedByAgencyMemberId];
        }
        else if ($scope.profile.agencyAssocUpdatedByCaregiverId) {
          $scope.profile.updatedByName = getCaregiverNameById($scope.profile.agencyAssocUpdatedByCaregiverId)
        }
      }
    }

    mapCaregiverPhoneNumbers();
    mapCaregiverCertifications();
    initActivityDetails();

    $scope.agencyCertificationsExtraSettings = {
      styleActive: true,
      showUncheckAll: false,
      scrollable: true,
      selectedToTop: true,
      externalIdProp: '',
    };

    // Create key/value object for notes amounts
    // $scope.sectionNotesAmount = {};
    // for (const sectionObj of $scope.profile.generalNotesAmount) {
    //   $scope.sectionNotesAmount[sectionObj.section] = sectionObj.amount
    // }

    $scope.caregiverFullName = [$scope.profile.firstName, $scope.profile.middleName, $scope.profile.lastName].filter(namePart => namePart !== null).join(' ');

    if ($scope.profile.certifications.length > 0) {
      if (applicationLandingCertification) {
        $scope.selectedApplicationCertification = applicationLandingCertification;
      } else if ($scope.profile.certificationsModel.length > 0) {
        $scope.selectedApplicationCertification = $scope.profile.certificationsModel[0].label;
      }
      getCaregiverApplicationAnswers();
    }

    initCaregiverStatusHistoryTable();
    initCaregiverPreferences();
  }

  $scope.filterSectionLabelInComplience = function (complianceItems, section) {
    if (complianceItems && section) {
      return complianceItems.filter(x => x.sectionLabel === section).length > 0;
    } else {
      return false;
    }
  }

  $scope.calculateDayToExpireAsText = (complianceItem) => {
    if (!complianceItem.lastUploadedVersion || !complianceItem.lastUploadedVersion.expirationDate) {
      return null;
    }
    const now = moment();
    const expirationDate = moment(complianceItem.lastUploadedVersion.expirationDate);
    const diff = expirationDate.diff(now, 'days');
    if (diff > 0) {
      return `Expires in ${diff} day${diff > 1 ? `s` : ``}`;
    } else {
      return `Expired ${diff * -1} day ${diff < -1 ? `s ago` : ` ago`}`;
    }
  }

  $scope.updateComplianceItemStatus = function (caregiverComplianceItemId, compliantItemStatus) {
    let body = {
      isCompliant: compliantItemStatus.isCompliant
    }

    if (compliantItemStatus.id) {
      body.caregiverComplianceItemStatusId = compliantItemStatus.id
    }

    DatabaseApi.put('hr/agencies/' + $rootScope.agencyId +
      '/caregivers/' + proId + '/compliance_items/' + caregiverComplianceItemId + '/update_compliance_item_status', body)
      .then(function (res) {
        compliantItemStatus.id = res.data;
        toaster.pop('success', "Compliance item status updated successfully");
      }, function (err) {
        toaster.pop('error', 'Something went wrong');
      });
  }

  $scope.loader = function () {
    $scope.excText = 'Loading...';
    $timeout(function () {
      $scope.excText = 'Not in exclusion lists';
    }, 2500)
  };

  $scope.setDocAFieldAtComplianceItem = function (caregiverComplianceItem, answer) {
    caregiverComplianceItem.docA = answer;
  }

  var days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];

  $scope.getDayName = function (day) {
    return days[day];
  };

  $scope.languages = languages;

  $scope.genders = [
    { value: 'M', text: 'Male' },
    { value: 'F', text: 'Female' }
  ];

  $scope.showLangs = function () {
    if (!$scope.profile) return;
    var selected = [];
    angular.forEach($scope.languages, function (s) {
      if ($scope.profile.languages && $scope.profile.languages.indexOf(s.value) >= 0) {
        selected.push(s.text);
      }
    });
    return selected.length ? selected.join(', ') : 'Not set';
  };

  $scope.showOffices = function () {
    if (!$scope.profile) return;
    var selected = $scope.profile.offices.map(function (office) {
      return $scope.offices.find(function (obj) { return obj.id === office.id; }).label;
    });
    return selected.length ? selected.join(', ') : 'Not set';
  };

  $scope.goToPros = function () {
    $state.go('app.professionals.caregivers');
  };
  $scope.goToSingleVisit = function (id) {
    $rootScope.caregiverModalInstance.close();
    setTimeout(() => {
      $rootScope.openVisitBroadcastModalById(id);
    });
  };
  $scope.goToPendingApplication = function () {
    $state.go('app.professionals.pendingApplication');
  }

  $scope.popTest = function (test, video) {
    console.log(video);
    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/video-test-modal.html',
      size: 'lg',
      controller: 'videoTestModalCtrl',
      resolve: {
        test: function () { return test; },
        dueDateId: function () { return video.dueDate.id; },
        caregiverId: function () { return proId }
      }

    });
    modalInstance.result.then(function (res) {
    }, function () {
    });

  };

  $scope.beforeUpdateHireDate = function (oldHireDate) {
    $scope.oldHireDate = oldHireDate;
  }

  $scope.beforeUpdateRehireDate = function (oldRehireDate) {
    $scope.oldRehireDate = oldRehireDate;
  }

  $scope.updateHireDate = ({ rehire }) => {
    const model = rehire ? "rehireDate" : "hireDate";
    const oldModel = rehire ? "oldRehireDate" : "oldHireDate";

    if ($scope[model] === $scope[oldModel]) {
      return;
    }

    if (!allDocs) {
      toaster.pop(
        "error",
        'Error: Updating hire date requires "View Caregiver Page Documents" permission.'
      );
      return;
    }

    const caregiverForms = [];

    const formsData = [
      { id: 2, formName: "Individual characteristics form (ICF) work opportunity tax credit" },
      { id: 3, formName: "8850 Pre-Screening notice and certification request for the work opportunity credit" },
      { id: 4, formName: "IT- 2104 Employee's withholding allowance certificate" },
      { id: 5, formName: "Form W-4" },
      { id: 7, formName: "I-9 Employment eligibility verification department of homeland security " },
      { id: 71, formName: "CT W-4" },
      { id: 73, formName: "Paychex Enrollment Form" },
      { id: 77, formName: "Insurance enrollment or waiver form" },
      { id: 78, formName: "Commuter Benefits Participation Form" },
      { id: 87, formName: "Form W-4 2021" },
      { id: 88, formName: "IT- 2104 Employee's withholding allowance certificate (2021)" },
      { id: 89, formName: "Employee Affidavit for the Hire a Veteran Credit DTF-75" },
      { id: 457, formName: "2020 W-4" },
    ];

    formsData.forEach((form) => {
      if (allDocs.find((doc) => doc.documentType === form.id) !== undefined) {
        caregiverForms.push(form.formName);
      }
    });

    if (caregiverForms.length === 0) {
      $scope.updatePro(model);
      return;
    }

    const newScope = $scope.$new();
    newScope.profile = $scope.profile;
    newScope.forms = caregiverForms;

    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/update-caregiver-hire-date-modal.html",
      size: "md",
      controller: "updateCaregiverHireDateModalCtrl",
      scope: newScope,
    });
    modalInstance.result.then(function (res) {
      if (res === "confirm") {
        $scope.updatePro(model);
      } else {
        $scope.profile[model] = $scope[oldModel];
      }
    });

  }

  $scope.beforeUpdatePro = (oldVal) => {
    $scope.updateProOldVal = oldVal;
  }

  $scope.shouldDisableSSNEdit = () => {
    return !$rootScope.user.permissions.includes('show_caregiver_ssn');
  }

  $scope.updatePro = async function (field) {
    if (!field) return;
    let method = 'patch';
    let url = "caregiver/:caregiverId"
      .replace(":caregiverId", $scope.profile.id);
    let body = {};
    const fieldData = $scope.profile[field]
    body[field] = fieldData;

    switch (field) {
      case "emergencyContacts":
        let invalidNumbers = false;
        fieldData.forEach(contact => {
          if (contact.livesWithPatient === undefined) {
            contact.livesWithPatient = null;
          }
          if (
            ![null, undefined, ""].includes(contact.phone1) &&
            phoneUtils.isValidNumber(contact.phone1)
          ) {
            contact.phone1 = phoneUtils.formatE164(contact.phone1);
          } else {
            contact.phone1 = "";
            invalidNumbers = true;
          }
          if (![null, undefined, ""].includes(contact.phone2)) {
            if (phoneUtils.isValidNumber(contact.phone2)) {
              contact.phone2 = phoneUtils.formatE164(contact.phone2);
            } else {
              contact.phone2 = "";
              invalidNumbers = true;
            }
          } else {
            contact.phone2 = "";
          }
        });
        body[field] = fieldData;
        if (invalidNumbers) {
          mapCaregiverPhoneNumbers();
          $scope.showEditEmergencyContacts = true;
          toaster.pop('error', "Invalid phone numbers", "Emergency contacts not updated");
          return;
        }
        method = "put";
        url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/agency_assoc"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.profile.id);
        break;
      case "applicationStartDate":
      case "firstWorkDate":
      case "lastWorkDate": {
        method = "put";
        url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/agency_assoc"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.profile.id);
        break;
      }
      case "hireDate": {
        method = "put";
        url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/hire_date"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.profile.id);
        break;
      }
      case "terminationDate": {
        method = "put";
        url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/termination_date"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.profile.id);
        break;
      }
      case "isClockEnabled": {
        method = "put";
        url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/enable_clock_in"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.profile.id);
        break;
      }
      case "address": {
        if (!body.address || !body.address.geometry) return;
        try {
          const address = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(body.address)
          body.address = address.formatedAddressDetails.fullAddress;
          body.addressGeoLocation = address.formatedAddressDetails.location;
          body.addressComponents = address;
        }
        catch (e) {
          toaster.pop('error', 'Invalid address: ' + e.message);
        }
        break;
      }
      case "homePhone":
      case "phoneNumber": {
        let phone = $scope.profile[field];
        phone = phone.replace(/ /g, '');
        phone = phone.replace(/\)/g, '');
        phone = phone.replace(/\(/g, '');
        phone = phone.replace(/\+/g, '');
        body[field] = '+' + phone;
        break;
      }
      case "phoneNumbers": {
        $scope.profile.phoneNumbers.forEach(function (phone, i) {
          phone.phoneNumber = phone.phoneNumber.replace(/ /g, '');
          phone.phoneNumber = phone.phoneNumber.replace(/\)/g, '');
          phone.phoneNumber = phone.phoneNumber.replace(/\(/g, '');
          phone.phoneNumber = phone.phoneNumber.replace(/\+/g, '');
          body.phoneNumbers[i].phoneNumber = '+' + phone.phoneNumber;
        });
        break;
      }
      case "ssn": {
        if (!generalUtils.isSsnValid($scope.profile[field])) {
          $scope.profile[field] = $scope.updateProOldVal;
          toaster.pop('error', 'Invalid SSN');
          return;
        }
        body.ssn = $scope.profile[field];
        break;
      }
      case "email": {
        if (body[field] === '') {
          body[field] = null;
        }
        break;
      }
      default:
        break;
    }

    DatabaseApi[method](url, body).then(() => {
      toaster.pop('success', "Caregiver Updated");
      DatabaseApi.caregivers('refresh');


      if (field === 'address'){

        const getCaregiverUrl = "agencies/:agencyId/caregivers/:caregiverId"
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":caregiverId", $scope.caregiverId);

        DatabaseApi.get(getCaregiverUrl).then((res) => {
          $scope.profile = res.data;
          if ($rootScope.showBillingFeature && ($scope.profile.missingAddressFields.length > 0)){
              const modal = mfModal.create({
                subject: "Incomplete address",
                message: `We were unable to recognize parts of the address, which can cause several processes to fail. Please validate the address prior adding it.
                \nAdditional free text can be added to the Address2 field.`,
                variant: "danger",
                confirmLabel: "Confirm",
                hideCancelButton:true,
                preventBackdropClose: true,
                onConfirm: () => {
                  modal.close()
              }
            });
          }
        });
      }

      Analytics.event('caregiver-details-edit', {
        cargiverId: $scope.profile.id
      });

      if (field === 'emergencyContacts') {
        mapCaregiverPhoneNumbers();
      }
    }, (err) => {
      DatabaseApi.caregivers('refresh');
      let errorTitle = "Something Went Wrong";
      let errorMessage = "Please try again";

      if (field === 'email' && err && err.status === 409) {
        const { error } = err.data;
        if (error) {
          errorMessage = error.charAt(0).toUpperCase() + error.slice(1);
        }
      } else if (err && err.data && err.data.error) {
        errorTitle = "Invalid Input";
        errorMessage = err.data.error;
      }

      toaster.pop('error', errorTitle, errorMessage);
    });
  };

  $scope.removeFromAgency = function (id) {
    $scope.text = 'Removing...';
    DatabaseApi.delete('caregiver/' + id).then(function (res) {
      $scope.text = 'Caregiver Removed';
    }, function (err) {
      $scope.text = 'An Error Occoured';
    });
  };

  function initEngagementTable(data) {
    var engagementsTableOptions = {
      count: 10,
      sorting: { broadcastedAt: "desc" }
    };
    $scope.engagementsTable = new NgTableParams(engagementsTableOptions, {
      counts: [10, 25, 50, 100],
      dataset: data
    });
  }

  $scope.yesNoOptions = [
    { id: 0, label: 'Yes', value: true },
    { id: 1, label: 'No', value: false }
  ];

  $scope.yesNoExtraSettings = {
    styleActive: true,
    selectionLimit: 1,
    singleSelection: true,
    closeOnSelect: true,
    smartButtonMaxItems: 1
  };

  $scope.applicationCertificationsExtraSettings = {
    styleActive: true,
    selectionLimit: 1,
    singleSelection: true,
    closeOnSelect: true,
    smartButtonMaxItems: 1,
    externalIdProp: ''
  };

  $scope.yesNoTranslationTexts = {
    uncheckAll: 'Clear'
  };

  $scope.filterUploadedDocumentsBy = {
    isHr: {},
    isCompliance: {},
    isPassport: {},
  };

  $scope.uploadedDocumentsGlobalFilter = { val: "" };
  $scope.applyUploadedDocumentsGlobalSearch = (term) => {
    const filter = { $: term };
    if ($scope.uploadedDocumentsTable) {
      angular.extend($scope.uploadedDocumentsTable.filter(), filter);
    } else {
      console.log("no table");
    }
  }

  // $scope.areAllDocumentsSelected = false;

  // $scope.anyDocumentSelected = () => {
  //   if (!$scope.filteredUploadedDocuments || !Array.isArray($scope.filteredUploadedDocuments)) {
  //     return false;
  //   }
  //   return $scope.filteredUploadedDocuments.some(doc => doc.selected === true);
  // }

  // $scope.toggleSelectAllDocuments = (areAllDocumentsSelected) => {
  //   $scope.areAllDocumentsSelected = areAllDocumentsSelected;
  //   if (!$scope.preventSingleDocumentsSelectChange) {
  //     $scope.filteredUploadedDocuments.forEach(doc => doc.selected = areAllDocumentsSelected);
  //     $scope.uploadedDocumentsTable.data.forEach(doc => doc.selected = areAllDocumentsSelected);
  //   } else {
  //     $scope.preventSingleDocumentsSelectChange = false;
  //   }
  // }

  // $scope.updateAreAllDocumentsSelected = () => {
  //   const areAllDocumentsSelected = $scope.filteredUploadedDocuments.find(doc => doc.selected === false) === undefined;
  //   if ($scope.areAllDocumentsSelected !== areAllDocumentsSelected) {
  //     $scope.areAllDocumentsSelected = areAllDocumentsSelected;
  //     $scope.preventSingleDocumentsSelectChange = true;
  //     document.getElementById("checkboxSelectAll").click();
  //   }
  // }

  // $scope.setDownloading = (val) => $scope.printButtonLoading = val;

  // $scope.printSelectedUploadedDocuments = async () => {
  //   $scope.setDownloading(true);
  //   if (!$scope.caregiverUploadedDocuments || !Array.isArray($scope.caregiverUploadedDocuments)) {
  //     return false;
  //   }
  //   try {

  //     const selectedUploads = [];
  //     $scope.caregiverUploadedDocuments.forEach(doc => {
  //       selectedUploads.concat(doc.uploads.filter(upload => upload.selected));
  //     });
  //     const urls = selectedUploads.map(upload => upload.fileUrl);
  //     const fetchPromisesWithTypes = [];
  //     urls.forEach(url => {
  //       const fetchUrlPromise = fetch(url).then(res => res.arrayBuffer());
  //       if (url.includes(".pdf")) {
  //         fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'pdf' });
  //       } else  if (url.includes(".png")) {
  //         fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'png' });
  //       } else if (url.includes(".jpg")) {
  //         fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'jpg' });
  //       } else if (url.includes(".jpeg")) {
  //         fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'jpeg' });
  //       }
  //     });

  //     const bytes = await combinePdf(fetchPromisesWithTypes);

  //     download(bytes, "Caregiver Documents.pdf", "application/pdf");
  //   } catch (e) {
  //     toaster.pop('error', 'Failed downloading PDF');
  //     console.error(e);
  //   } finally {
  //     $scope.setDownloading(false);
  //   }
  // }

  $scope.updateDownloadLink = (url) => {
    $scope.downloadFile = url;
  }

  $scope.uploadedDocumentsFilterTable = (startDate, endDate) => {
    if (!$scope.caregiverUploadedDocuments || !Array.isArray($scope.caregiverUploadedDocuments)) {
      return;
    }
    const filtered = $scope.caregiverUploadedDocuments.filter(doc => {
      if (startDate !== undefined && endDate !== undefined) {
        const createdAt = moment(doc.createdAt);
        if (!createdAt.isBetween(startDate.startOf("day"), endDate.endOf("day"))) {
          return false;
        }
      }

      if ($scope.filterUploadedDocumentsBy.isHr.id !== undefined) {
        const selectedIsHr = $scope.filterUploadedDocumentsBy.isHr.id === 0;
        if (doc.isHr !== selectedIsHr) {
          return false;
        }
      }

      if ($scope.filterUploadedDocumentsBy.isCompliance.id !== undefined) {
        const selectedIsCompliance = $scope.filterUploadedDocumentsBy.isCompliance.id === 0;
        if (doc.isCompliance !== selectedIsCompliance) {
          return false;
        }
      }

      if ($scope.filterUploadedDocumentsBy.isPassport.id !== undefined) {
        const selectedIsForPassport = $scope.filterUploadedDocumentsBy.isPassport.id === 0;
        if (doc.isPassport !== selectedIsForPassport) {
          return false;
        }
      }

      return true;
    });

    $scope.filteredUploadedDocuments = filtered;
    // $scope.updateAreAllDocumentsSelected();

    initUploadedDocumentsTable(filtered);
  }

  // $scope.getUploadedDocumentsSelectedAmount = () => {
  //   if (!$scope.filteredUploadedDocuments) return "";
  //   return $scope.filteredUploadedDocuments.filter(doc => doc.selected).length;
  // }

  $scope.$watch('filterUploadedDocumentsBy', function () {
    $scope.uploadedDocumentsFilterTable();
  }, true);

  function initUploadedDocumentsTable(data, initial = false) {
    if (!data) return;

    if (initial) {
      let initStartDate = null;
      let initEndDate = null;
      data.forEach(doc => {
        doc.uploads.forEach(upload => {
          const createdAt = moment(upload.createdAt);
          if (initStartDate === null || createdAt.isBefore(initStartDate)) {
            initStartDate = createdAt;
          }
          if (initEndDate === null || createdAt.isAfter(initEndDate)) {
            initEndDate = createdAt;
          }
        });
      });
      $scope.uploadedDocumentInitDates = {
        from: initStartDate,
        to: initEndDate
      };
    }
    data.forEach(doc => {
      doc.uploads.forEach(upload => {
        if (upload.expiryDate) {
          upload.expiryDate = new Date(upload.expiryDate);
        }
      });
    })

    var options = {
      count: 10,
      sorting: { category: "asc" }
    };

    $scope.uploadedDocumentsTable = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: data
    });

    if ($scope.uploadedDocumentsGlobalFilter.val) {
      $scope.applyUploadedDocumentsGlobalSearch($scope.uploadedDocumentsGlobalFilter.val)
    }
  }

  $scope.activeUploadedDocumentsRowsIdsMap = {};

  $scope.clickUploadedDocumentRow = (row) => {
    if ($scope.activeUploadedDocumentsRowsIdsMap[row.documentTypeId]) {
      $scope.activeUploadedDocumentsRowsIdsMap[row.documentTypeId] = false;
    } else {
      $scope.activeUploadedDocumentsRowsIdsMap[row.documentTypeId] = true;
    }
  }

  $scope.updateUploadedDocumentExpiryDate = (upload, oldExpirydate) => {
    const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregiver_uploaded_document/${upload.uploadId}`;
    const body = {
      expiryDate: upload.expiryDate ? LocalDate.from(nativeJs(upload.expiryDate)) : null
    };
    DatabaseApi.put(url, body).then((res) => {
      toaster.pop("success", "Document updated successfully");
    }, (err) => {
      toaster.pop("error", "Oops! Something went wrong", "could not update document");
      upload.expiryDate = oldExpirydate ? new Date(oldExpirydate.slice(1, oldExpirydate.length - 1)) : null;
    })
  }

  $scope.openLightboxModal = function (url) {
    var images = [url];
    Lightbox.openModal(images, 0);
  };

  $scope.remind = function () {
    DatabaseApi.post('caregivers/' + $scope.profile.id + '/reminder').then(function (res) {
      toaster.pop('success', "Success", "Reminder Sent!");
    }, function (err) {
      console.log('something went wrong');
      toaster.pop('error', "Something Went Wrong", 'Please try again');
    });
  };

  $rootScope.$on('caregiver_changed', function (event, data) {
    if ($scope.profile && data && data.id == proId) {
      setCaregiversStatus();
      getCaregiverDocs();
    }
  });

  function setCaregiversStatus() {
    if (!$scope.profile) return;
    var caregiversMap = DatabaseApi.caregivers() || {};
    if ($scope.profile.id) {
      $scope.profile.online = caregiversMap[$scope.profile.id.toString()] ? caregiversMap[$scope.profile.id.toString()].online : false;
    }
  }
  setCaregiversStatus();

  $scope.cal2open = function ($event, elementOpened) {
    $event.preventDefault();
    $event.stopPropagation();

    $scope.cal2[elementOpened] = !$scope.cal2[elementOpened];
  };


  $scope.openExclusionListsModal = function () {

    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/exclusion-history-modal.html',
      size: 'lg',
      controller: 'exclusionListModalCtrl',
      resolve: {
        caregiverId: function () {
          return $scope.profile.id;
        },
      }
    });
    modalInstance.result.then(function (res) {
    }, function () {
    });
  };

  $scope.openUploadedDocumentNewTab = function (row) {
    const url = `agencies/${$rootScope.agencyId}/caregivers/${row.caregiverId}/document_uploads/${row.id}/generate_url`;
    DatabaseApi.get(url).then((res) => {
      window.open(res.data.fileUrl).focus();
    }, (err) => {
      toaster.pop('error', 'Something went wrong', 'could not get file');
    });
  }

  //////
  /////
  // zip
  //////
  /////

  $scope.zipFiles = function () {

    if (!files.list || files.list.length === 0) return toaster.pop('warning', 'No files');
    $scope.workingZip = true;

    var urls = [];
    var filesData = [];
    var filesCount = files.list.length;

    files.list.forEach(function (file) {
      //console.log('file', file);
      $scope.getFileUrl(file.id, function (url) {
        //console.log(url);
        urls.push(url);
        haveUrls();
      });
    });

    function haveUrls() {
      if (urls.length !== filesCount) return;

      urls.forEach(function (url) {
        if (url === '') {
          filesData.push({ data: null });
          haveFiles();
        } else {
          fetch(url)
            .then(function (response) {
              if (response.status === 200 || response.status === 0) {
                var split = url.split('/');
                split = split[split.length - 1].split('?')[0];
                filesData.push({ data: response.blob(), name: split });
                haveFiles();
              }
            })
        }
      });
    }

    function getFileName(name) {
      var nameSplit = name.split('-');
      var docId = parseInt(nameSplit[1]);
      var docName = '';
      docTypesCache.forEach(function (doc) {
        if (doc.id === docId) {
          docName = decodeURI(doc.name);
        }
      });

      name = name.replace('doc-' + docId, docName.toLowerCase());
      var split = name.split('.');
      name = split[0] + '.' + (split[2] !== undefined ? split[2] : split[1]);
      name = name.replace(/ /g, '-');
      name = name.replace(/%3A/g, '-');
      name = decodeURI(name);
      return name;
    }

    function haveFiles() {

      if (filesData.length !== filesCount) return;

      var zip = new JSZip();
      filesData.forEach(function (file) {
        if (file.data) zip.file(getFileName(file.name), file.data);
      });

      zip.generateAsync({ type: "blob" }).then(function (content) {
        let blob = new Blob([content], { type: "application/zip" });
        let url = $window.URL || $window.webkitURL;
        $scope.zipFileUrl = url.createObjectURL(blob);
        console.log("Done creating zipfile: ", $scope.zipFileUrl);
        $timeout(function () {
          //document.getElementById('zip-link').click();
          //angular.element(document).find('#zip-link')[0].click();
        }, 50);
        $scope.workingZip = false;
        $scope.haveZipFile = true;

        // see FileSaver.js
        //saveAs(content, "example.zip");
      });
    }

  };

  $scope.zipFilesReset = function () {
    $scope.haveZipFile = false;
  }

  $scope.getApplicantProfile = function (id) {
    $scope.isLoading = true;
    const subSectionsOptions = [];
    $scope.answers.forEach(function (section) {
      section.subSections.forEach(function (subsection) {
        if (subsection.data.id > 0 && subsection.isDone) {
          subSectionsOptions.push({ id: subsection.data.id, label: subsection.data.title });
        }
      });
    });

    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/application-full-profile-subsections-selection-modal.html',
      size: 'md',
      controller: 'applicationFullProfileSubsectionsSelectionModalCtrl',
      resolve: {
        subSectionsOptions: () => subSectionsOptions,
        caregiverId: () => $scope.profile.id,
        caregiverName: () => `${$scope.profile.firstName} ${$scope.profile.lastName}`
      }
    });

    modalInstance.result.then((res) => {
      $scope.applicantProfileLink = res;
      $scope.isLoading = false;
    }, () => {
      $scope.isLoading = false;
    });
  };

  // $scope.openApplicantDownaloadModal = function() {
  //     var modalInstance = $uibModal.open({
  //         templateUrl: 'admin/views/applicant-download-modal.html',
  //         size: 'lg',
  //         controller: 'applicantDownloadModalCtrl',
  //         resolve: {
  //             modalData: $scope
  //         }
  //     });

  //     modalInstance.result.then(function (res) {

  //     }, function () {
  //     });
  // }

  $scope.openCoriModal = function (subsection) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/cori-modal.html',
      size: 'lg',
      controller: 'coriModalCtrl',
      resolve: {
        modalData: $scope,
        subsection: subsection,
      }
    });

    modalInstance.result.then((res) => {
      if (res === 'Completed') {
        getCaregiverApplicationAnswers();
      }
    }, () => {
    });
  }

  $scope.openI9Modal = function (subsection) {
    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/i9-modal.html',
      size: 'lg',
      controller: 'I9ModalCtrl',
      resolve: {
        modalData: $scope,
        subsection: subsection,
        caregiverId: $scope.profile.id
      }
    });

    modalInstance.result.then((res) => {
      if (res === 'Completed') {
        getCaregiverApplicationAnswers();
      }
    }, () => {
    });
  }

  $scope.open8850Modal = function (subsection) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/8850-modal.html',
      size: 'md',
      controller: '8850ModalCtrl',
      resolve: {
        modalData: $scope,
        subsection: subsection
      }
    });

    modalInstance.result.then((res) => {
      if (res === 'Completed') {
        getCaregiverApplicationAnswers();
      }
    }, () => {
    });
  }

  function setNotesData(notesType, calendarDay = undefined) {
    const notesModalConfig = {
      profileId: $scope.profile.id,
      notesType: notesType,
      calendarDay: calendarDay
    };

    entityNotesModalService.setNotesData(notesModalConfig);
  }

  $scope.notCompletedByDefaultFormDocumentIds = [75, 84, 86];

  $scope.openAgencyCompleteFormModal = function (subsection) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/agency-complete-form-modal.html',
      size: 'md',
      controller: 'agencyCompleteFormModalCtrl',
      resolve: {
        modalData: $scope,
        subsection: subsection,
      }
    });

    modalInstance.result.then((res) => {
      if (res === 'Completed') {
        getCaregiverApplicationAnswers();
      }
    }, () => {
    });
  }

  $scope.clickApplicationRow = function ($event, row, idx, type) {
    console.log($event);
    console.log(row);

    if ($scope.activeRowApplication) {
      angular.element(document.getElementById('active-application-row')).remove();
      angular.element(document.getElementById('active-application-row')).remove();
      if (type === 'sections') {
        if ($scope.activeRowApplication.htmlType !== type) {

        }
        else if ($scope.activeRowApplication.data.id === row.data.id) {
          $scope.activeRowApplication = null;
          return;
        }
      } else if (type === 'documents') {
        if ($scope.activeRowApplication.htmlType !== type) {

        }
        else if ($scope.activeRowApplication.id === row.id) {
          $scope.activeRowApplication = null;
          return;
        }
      }
    }


    $scope.activeRowApplication = angular.copy(row);
    $scope.activeRowApplication.htmlType = type;

    var html = "<div ng-include src=\"'admin/views/professional-application-table-row.html'\" include-replace></div>";

    var elem = $event.currentTarget;

    angular.element(elem).after($compile(html)($scope));

  };

  $scope.shouldShowQuestion = (question) => {
    return question.isHidden === false && !['Placeholder', 'Signature', 'PlainText', 'TitleText'].includes(question.type)
  }

  $scope.shouldShowTrashCan = (question) => {
    return (
      $rootScope.isOnboardingAgency &&
      question.type !== "Document" &&
      (question.answer || question.chosenAnswerId || question.chosenAnswerIds)
    );
  }

  $scope.onClickRejectHRAnswer = function (question) {
    const modal = mfModal.create({
      subject: "Reason of rejection",
      variant: "info",
      message: `Pleas eneter the reason of the rejection`,
      inputPlaceholder: "Enter reason here",
      hideCancelButton: false,
      layoutOrder: ["message", "input"],
      showInput: true,
      confirmLabel: "Yes, confirm",
      onConfirm: ({ inputModel }) => modal.close() && submitHRAnswerRejection(question, inputModel),
      onComplete: () => modal.close(),
      onCancel: () => {},
    });
  }

  function submitHRAnswerRejection(question, reason) {
    const url = wildcard(
      "hr/agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/hr_application_answers/:hrQuestionId",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.caregiverId,
      question.id
    )
    DatabaseApi.patch(url, {
      rejectStatus: {
        rejected: true,
        reason: reason
      }
    }).then(() => {
      question.answerRejectionStatus = {
        status: "Rejected",
        rejectedAt: Instant.now(),
        rejectedBy: $rootScope.agencyMemberId,
        reason: reason
      }
      toaster.pop('success', "Success", "Answer Rejected Successfully")
    });
  }

  $scope.clickApplicationOpendRow = function (close) {
    if (close) {
      angular.element(document.getElementById('active-application-row')).remove();
      angular.element(document.getElementById('active-application-row')).remove();
      $scope.activeRowApplication = null;
    }
  };

  $scope.caregiverOfficesEvents = {
    onItemSelect: function (item) {
      updateCaregiverOffices([item.id], "ASSOCIATE");
    },

    onItemDeselect(item) {
      // Disallow removing all offices from a caregiver
      if ($scope.profile.offices.length === 0) {
        SweetAlert.swal({
          title: 'Update offices',
          text: "Caregiver must have at least 1 office associated",
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "OK",
          closeOnConfirm: true
        });
        $scope.profile.offices.push(item);
        return;
      }
      updateCaregiverOffices([item.id], "DISSOCIATE");
    },

    onSelectAll() {
      var officeIds = $scope.offices.map(office => office.id)
      updateCaregiverOffices(officeIds, "ASSOCIATE");
    },

    // Commented due to disallow removing all offices from a caregiver
    // onDeselectAll() {
    //   var officeIds  = $scope.offices.map(office => office.id)
    //   updateCaregiverOffices(officeIds, "DISSOCIATE");
    // },
    // called after select events happen
    onSelectionChanged() {
      $scope.profile.offices = $scope.profile.offices.filter(function (office) {
        return $rootScope.user.agencyMember.officeIds.includes(office.id)
      })
    }
  }

  function updateCaregiverOffices(selectedOfficeIds, associateType) {

    var nonRelatedToOffices = selectedOfficeIds.reduce(function (result, officeId) {
      if (!$rootScope.user.agencyMember.officeIds.includes(officeId)) {
        result.push(officeId);
      }
      return result;
    }, []);

    if (nonRelatedToOffices.length > 0) {
      SweetAlert.swal({
        title: 'Update offices',
        text: "Agency member cannot edit offices he doesn't belong to",
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "OK",
        closeOnConfirm: true
      });
      return;
    }

    var reqObject = {
      associations: []
    };

    reqObject.associations = selectedOfficeIds.map(officeId => {
      return {
        caregiverId: $scope.profile.id,
        officeId: officeId,
        type: associateType
      }
    });

    officesService.updateCaregiversOffices($rootScope.agencyId, $rootScope.agencyMemberId, reqObject).then((res) => {
    }).then(function (res) {
      toaster.pop('success', "Success", "Caregiver offices updated successfully")
    }).catch(err => {
      toaster.pop("error", "Failed to update caregiver offices");
    });
  }

  $scope.showBranches = function () {
    if (!$scope.profile) return;
    const selected = $scope.profile.branches.map((branch) => {
      return $scope.agencyBranches.find((obj) => {
        return obj.id === branch.id;
      }).label;
    });
    return selected.length ? selected.join(", ") : "Not set";
  };

  $scope.caregiverBranchesEvents = {
    onSelectionChanged() {
      updateCaregiverBranches();
    },
  };

  function updateCaregiverBranches() {
    const caregiverId = $scope.profile.id;
    const body = {
      branchIds: $scope.profile.branches.map((branch) => branch.id)
    };
    agencyBranchService
      .updateCaregiversBranches(
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        caregiverId,
        body
      )
      .then((res) => {
        toaster.pop(
          "success",
          "Success",
          "Caregiver branches updated successfully"
        );
      })
      .catch((err) => {
        toaster.pop("error", "Failed to update caregiver branches");
      });
  }

  $scope.caregiverCertificationEvents = {
    onItemSelect: function (item) {
      const certificationItem = $scope.agencyCertifications.find(obj => obj.id === item.id);
      $scope.profile.certifications.push(certificationItem.label);
      updateCaregiverCertifications();
    },
    onItemDeselect(item) {
      const certificationItem = $scope.agencyCertifications.find(obj => obj.id === item.id);
      $scope.profile.certifications = $scope.profile.certifications.filter(cert => cert !== certificationItem.label);
      // Disallow removing all certifications from a caregiver
      if ($scope.profile.certificationsModel.length === 0) {
        SweetAlert.swal({
          title: 'Update Certifications',
          text: "Caregiver must have at least 1 certification associated",
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "OK",
          closeOnConfirm: true
        });
        $scope.profile.certificationsModel.push(certificationItem);
        return;
      }
      updateCaregiverCertifications();
    },
    onSelectAll() {
      $scope.profile.certificationsModel.push(...$scope.agencyCertifications);
      $scope.agencyCertifications.forEach(obj => {
        if ($scope.profile.certifications.indexOf(obj.label) === -1) {
          $scope.profile.certifications.push(obj.label);
        }
      });
      updateCaregiverCertifications();
    }
  };

  function updateCaregiverCertifications() {
    const body = {
      certifications: $scope.profile.certifications
    };
    const url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId +
      '/caregivers/' + $scope.profile.id + '/certifications';

    DatabaseApi.put(url, body)
      .then((res) => {
        DatabaseApi.setCaregiverProperty($scope.profile.id, 'certifications', $scope.profile.certifications);
        $rootScope.$broadcast('caregiver_changed');
        toaster.pop(
          "success",
          "Success",
          "Caregiver certifications updated successfully"
        );
      })
      .catch((err) => {
        toaster.pop("error", "Failed to update caregiver certifications");
      });
  }

  $scope.handleApplicationCertificationChange = (certification) => {
    if ($scope.selectedApplicationCertification !== certification) {
      $scope.selectedApplicationCertification = certification;
      getCaregiverApplicationAnswers();
    }
  }

  function setSelectedItems({ newVisits, newVacations }) {
    entityNewVisitModalService.setSelectedItems({ visits: newVisits, vacations: newVacations });
  }

  function getSelectedVisits() {
    $scope.selectedVisits = entityNewVisitModalService.selectedItems.visits;
  }

  const summaryColumn = {
    heading: "Summary",
    className: "extra",
    html: (items) => {
      const aggregations = {
          scheduledTime: 0,
          recordedTime: 0,
          pto: 0,
      };

      items.forEach(dayContainer => {
          aggregations.scheduledTime += dayContainer.items
              .filter(filterSummaryVisits)
              .reduce((acc, visit) => acc + (new Date(visit.payload.endTime) - new Date(visit.payload.startTime)) / 1000, 0);

          aggregations.recordedTime += dayContainer.items
              .filter(filterSummaryVisits)
              .filter(visit => visit.payload.clockinTime !== null && visit.payload.clockoutTime !== null)
              .reduce((acc, visit) => acc + (new Date(visit.payload.clockoutTime) - new Date(visit.payload.clockinTime)) / 1000, 0);

          aggregations.pto += dayContainer.items
              .filter(visit => visit.type === "PAID_TIME_OFF")
              .reduce((acc, visit) => acc + (new Date(visit.payload.endTime) - new Date(visit.payload.startTime)) / 1000, 0);
      });

      return`
          <div class="calendar-summary">
              <p><span>Scheduled:</span><strong>${formatSeconds(aggregations.scheduledTime)}</strong></p>
              <p><span>Recorded:</span><strong>${formatSeconds(aggregations.recordedTime)}</strong></p>
              <p><span>PTO:</span><strong>${formatSeconds(aggregations.pto)}</strong></p>
          </div>
      `
      },
  };

  function getSummaryColumn() {
    const column = angular.copy(summaryColumn);

    column.hide = !$scope.showSummaryColumn;

    return column;
  }

  function setExtraColumns() {
      $scope.extraColumns = [
          getSummaryColumn(),
      ];
  }

  setExtraColumns();

function formatSeconds(seconds) {
    const h = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds % 3600 / 60);

    const m = minutes > 9 ? `${minutes}` : `0${minutes}`;

    return `${h}:${m}h`;
}

const filterSummaryVisits = (visit) => ["ASSIGNED_VISIT", "UNSTAFFED_VISIT"].includes(visit.type);

  entityNewVisitModalService.registerObserverCallback(
    "visits",
    "professionalCtrl",
    getSelectedVisits
  );

  setSelectedItems({ newVisits: [], newVacations: [] });

  $scope.handleUnassignVisitsFromListView = () => {
    console.log('caregiver list view selected visits', $scope.selectedVisits);
    const body = {
      ids: $scope.selectedVisits.map(v => v.visitInstanceId),
      changes: {
        caregiverId: null
      }
    }

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/bulk_edit_visit_instance",
      $rootScope.agencyId,
      $rootScope.agencyMemberId
    );

    const visitsPreview = $scope.selectedVisits.map((visit, index) => {
      const startDateAndTime = $filter("mfShortTime")(visit.startTime, ['withDate']);
      const endTime = $filter("mfShortTime")(visit.endTime);
      return `${index + 1}. ${startDateAndTime} - ${endTime}`;
    }).join("\n");

    const modal = mfModal.create({
      subject: "Unassign from visits",
      message: `Are you sure you want to unassign from selected visits?\n\n${visitsPreview}`,
      cancelLabel: "Cancel",
      confirmLabel: "Unassign",
      variant: "danger",
      onConfirm: () => {
        modal.setLoading(true);

        DatabaseApi.post(url, body).then((res) => {
          if (res.data.skippedVisitsAndMissingPermissions || res.data.caregiversWithNoValidHireDate.length > 0) {
            VisitInstanceModalService.showSkippedVisitsErrorModal({
              skippedVisitsAndMissingPermissions: res.data.skippedVisitsAndMissingPermissions,
              updatedVisitsAmount: res.data.visitInstances.length,
              action: "update",
              overlappingAmount: 0,
              caregiversWithNoValidHireDate: res.data.caregiversWithNoValidHireDate
            });
          } else {
            toaster.pop("success", "Caregiver unassigned succesfully");
          }
          setSelectedItems({ newVisits: [], newVacations: [] });
          $rootScope.$emit("refresh_visits");
          modal.close();
          generalUtils.scrollToElement('scroll-assigned-visits');
        }, () => {
          toaster.pop("error", "Something went wrong", "Can't unassign from selected visits");
        }).finally(() => {
          modal.update({ isLoading: false });
        });
      }
    });
  }

  $scope.handleClickToggleDeletedVisits = () => {
    $scope.showDeletedVisitsOnCalendar = !$scope.showDeletedVisitsOnCalendar;
    localStorage.setItem("caregiverCalendarShowDeletedVisits", $scope.showDeletedVisitsOnCalendar);
  };

  $scope.handleClickToggleSummaryColumn = () => {
    $scope.showSummaryColumn = !$scope.showSummaryColumn;
    localStorage.setItem("caregiverCalendarShowSummaryColumn", $scope.showSummaryColumn);
    setExtraColumns();
  };

  $scope.exportCaregiverProfile = () => {
      $scope.isExportingProfile = true;

      exportProfileService
          .exportCaregiverProfilePDF(
              $rootScope.agencyId,
              $rootScope.agencyMemberId,
              $scope.caregiverId
          )
          .finally(() => $scope.isExportingProfile = false);
  };

  $scope.openExportCaregiverProfileModal = () => {
      $uibModal.open({
          templateUrl: 'admin/views/export-profile-modal.html',
          size: 'md',
          controller: 'exportCaregiverProfileModal',
          resolve: {
              agencyId: () => $rootScope.agencyId,
              agencyMemberId: () => $rootScope.agencyMemberId,
              caregiverId: () => $scope.caregiverId
          }
      })
  }

  function openCaregiverStatusModal() {
    $uibModal.open({
        templateUrl: 'admin/views/edit-caregiver-status-modal.html',
        size: 'md',
        controller: "editCaregiverStatusModalCtrl",
        resolve: {
            caregiver: $scope.profile,
            isFromPrioritizedCaregeiversScreen: false,
            onStatusChanged: () => $scope.onStatusChanges
        }
      });
  }

  $scope.onClickEditCaregiverStatus = () => {
    openCaregiverStatusModal();
  }

  $scope.onBeforeUpdateCaregiverFirstWorkDate = (oldVal) => {
    SweetAlert.swal({
      title: "First Work Date",
      text: "Are you sure you want to change the date?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, change",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        $scope.updatePro('firstWorkDate');
      } else {
        $scope.profile.firstWorkDate = oldVal;
      }
    });
  };

  getPatientsAssociated();
  function getPatientsAssociated() {
    DatabaseApi.get(
        "agencies/" +
        $rootScope.agencyId +
        "/agency_members/" +
        $rootScope.agencyMemberId +
        "/caregiver/" +
        $scope.caregiverId +
        "/patients_associated"
    )
        .then(function (res) {
            initPatientsTable(res.data.patients);
        })
        .catch(function (err) {
            toaster.pop(
                "error",
                "Something went wrong",
                "Could not get patients associated"
            );
        });
  }

  function initPatientsTable(data) {

    const options = {
        count: 10,
        sorting: { visitCount: "desc" },
    };

    const parsedData = data.map((patient) => {
        const displayName =
          patient.firstName + " " +
          (patient.middleName ? (patient.middleName + " ") : "") +
          patient.lastName +
          (patient.displayId ? ( " (" + patient.displayId + ")" ) : "")
        return {
            ...patient,
            displayName: displayName
        };
    });

    $scope.patientsTable = new NgTableParams(options, {
        counts: [],
        dataset: parsedData,
    });
  }

  $scope.removeStatusHistoryRow = (row) => { 
    const parsedEffectiveDate = moment(row.effectiveDate).format('MM/DD/YYYY');

    const removeStatusChangeURL = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/deleteFutureStatus/:futureStatusChangeId"
    .replace(":agencyId", $rootScope.agencyId)
    .replace(":agencyMemberId", $rootScope.agencyMemberId)
    .replace(":caregiverId", $scope.profile.id)
    .replace(":futureStatusChangeId", row.id);

    DatabaseApi.delete(removeStatusChangeURL).then((res) => {
      const getNextStatusChangeURL = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/getNextFutureStatusChange"
      .replace(":agencyId", $rootScope.agencyId)
      .replace(":agencyMemberId", $rootScope.agencyMemberId)
      .replace(":caregiverId", $scope.profile.id)

      DatabaseApi.get(getNextStatusChangeURL).then((res) => {
        setNextStatusAttributes(res.data.nextStatus, res.data.nextStatusEffectiveDate);

        initCaregiverStatusHistoryTable();

        toaster.pop(
          "success",
          `Remove future status change for ${parsedEffectiveDate}`,
        );
      }).catch((error) => {
        toaster.pop(
          "error",
          `Failed to remove future status change for ${parsedEffectiveDate}`,
          `Error in fetching next future status`
        );
      })
    }).catch((error) => {
      toaster.pop(
        "error",
        `Failed to remove future status change for ${parsedEffectiveDate}`,
        `Error in remove future status `
      );
    })
  }

  async function initCaregiverStatusHistoryTable() {
    const url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/status_history"
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId)
        .replace(":caregiverId", $scope.profile.id);

    const { data } = await DatabaseApi.get(url)

    data.rows.forEach((row) => {
      row.canBeDeleted = false;

      if (moment(row.effectiveDate).isAfter(moment())) {
        row.canBeDeleted = true;
      }
    })

    const options = {
        count: 10
    };

    $scope.statusReviewTable = new NgTableParams(options, {
        counts: [],
        dataset: data.rows
    });
  }

  function caregiverEvvStats() {
    // get caregivers docs
    DatabaseApi.get(
      "agencies/" +
        $rootScope.agencyId +
        "/agency_members/" +
        $rootScope.agencyMemberId +
        "/caregivers/" +
        $scope.caregiverId +
        "/evv_stats"
    ).then(
      function (res) {
        $scope.evvStats = '';
    // totalVisits: number
    // evvCount: number;
    // manualCount: number;
        if(res.data.evvCount + res.data.manualCount === 0 ) {
          $scope.evvStats = 0;
        } else {
          $scope.evvStats = (res.data.evvCount / (res.data.evvCount + res.data.manualCount)) * 100
        }

        $scope.evvStatsText = `# visits: ${res.data.totalVisits}, EVV: ${res.data.evvCount}, Not EVV: ${res.data.manualCount}`;

      },
      function (err) { }
    );
  }
  $scope.evvStats = undefined;
  caregiverEvvStats();

  $scope.selectedLangs = [];

  $scope.idLanguages = [
    { id: 1, label: "English" },
    { id: 2, label: "Spanish" },
    { id: 3, label: "Chinese" },
    { id: 4, label: "Portuguese" },
    { id: 5, label: "French" },
    { id: 6, label: "Russian" },
    { id: 7, label: "Arabic" },
  ];

  async function initCaregiverPreferences() {
    const url =
      "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/staffing_preferences"
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId)
        .replace(":caregiverId", $scope.profile.id);

    const req = await DatabaseApi.get(url);
    $scope.preferences = req.data;
    if ($scope.preferences.languages) {
      $scope.selectedLangs = $scope.idLanguages.filter((lang) =>
        $scope.preferences.languages.includes(lang.label)
      );
    }
  }

  $scope.patchCaregiverPreferences = function () {
    const url =
      "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/staffing_preferences"
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId)
        .replace(":caregiverId", $scope.profile.id);

    var body = {
      workWithKids: $scope.preferences.workWithKids,
      workWithSmokingPatient: $scope.preferences.workWithSmokingPatient,
      smokingCaregiver: $scope.preferences.smokingCaregiver,
      workWithPets: $scope.preferences.workWithPets,
      workWithBedbound: $scope.preferences.workWithBedbound,
      workWithWalkingAsistant: $scope.preferences.workWithWalkingAsistant,
      workWithKosher: $scope.preferences.workWithKosher,
      workWithHalal: $scope.preferences.workWithHalal,
      workWithHoyerLift: $scope.preferences.workWithHoyerLift,
      mainLanguage: $scope.preferences.mainLanguage,
      workWithShabat: $scope.preferences.workWithShabat,
      languages: $scope.idLanguages
        .filter((lang) => $scope.selectedLangs.map((lang) => lang.id).includes(lang.id))
        .map((lang) => lang.label),
      transportationType: $scope.preferences.transportationType,
      publicTransprotationTimeLimit: Number.parseInt(
        $scope.preferences.publicTransprotationTimeLimit
      ),
    };

    DatabaseApi.put(url, body).then(
      function (res) {
        toaster.pop("success", "Preference updated");
        $scope.preferences = res.data;
      },
      function (err) {
        toaster.pop("error", "Something went wrong");
      }
    );
  };

  $scope.editPreference = function (prop, val) {
    $scope.preferences[prop] = prop === val ? "" : val;
    $scope.patchCaregiverPreferences();
  };

  $scope.yesNoOptions = [
    { id: 0, label: "Yes", value: true },
    { id: 1, label: "No", value: false },
  ];

  $scope.selectSectionEvents = {
    onSelectionChanged: () => {
      $scope.patchCaregiverPreferences();
    },
  };

  $scope.extraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
    singleSelection: false,
  };
  
  $scope.showPrefLangs = function () {
    if (!$scope.preferences) return;
    var selected = [];
    angular.forEach($scope.languages, function (s) {
      if ($scope.preferences.languages && $scope.preferences.languages.indexOf(s.value) >= 0) {
        selected.push(s.text);
      }
    });
    return selected.length ? selected.join(', ') : 'Not set';
  };

  ///////////////////////
  // Caregiver Absence
  ///////////////////////

  $scope.handleCreateCaregiverAbsences = (selectedDate) => {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/new-caregiver-absence-modal.html',
      size: 'md',
      controller: 'newCaregiverAbsenceModalCtrl',
      windowClass: "center-center",
      resolve: {
        selectedDate: function () { return selectedDate },
        caregiverId: function () { return $scope.profile.id },
      },
    });
    return modalInstance.result;
  };

  $scope.handleDeleteAbsences = ({ selectedAbsences }, scrollToSection) => {
    if (!Array.isArray(selectedAbsences) || selectedAbsences.length === 0) {
      return;
    }

    let message = "";
    let subject = "";
    if (selectedAbsences.length === 1) {
      const absenceDate = $filter("mfShortDate")(selectedAbsences[0].payload.__original.date);
      message = `Are you sure you want to delete absence for ${absenceDate}?`;
      subject = `Delete absence`;
    } else {
      const absencesPreview = selectedAbsences.map((absence, index) => {
        const date = $filter("mfShortDate")(absence.payload.__original.date);
        return `${index + 1}. ${date}`;
      }).join("\n");
      message = `Are you sure you want to delete ${selectedAbsences.length} absences for the following dates?\n\n${absencesPreview}`;
      subject = `Delete absences`;
    }

    const modal = mfModal.create({
      subject: subject,
      message: message,
      layoutOrder: ["message"],
      cancelLabel: "Cancel",
      confirmLabel: "Delete",
      variant: "danger",
      onCancel: () => modal.close(),
      onConfirm: () => {
        modal.setLoading(true);
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/caregiver_absences/delete"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.profile.id);
        const body = {
          caregiverAbsenceIds: selectedAbsences.map(absence => absence.payload.__original.id)
        };
        DatabaseApi.post(url, body).then((res) => {
          toaster.pop("success", "Selected absences succesfully deleted");
          modal.close();
          $rootScope.$emit("refresh_visits");
          generalUtils.scrollToElement(scrollToSection);
        }, () => {
          toaster.pop("error", "Something went wrong", "Failed to delete absences");
          modal.setLoading(false);
        });
      },
    });
  };

  ////////////////////////
  // Emergency contacts
  ////////////////////////

  $scope.toggleEditEmergencyContacts = () => {
    $scope.showEditEmergencyContacts = !$scope.showEditEmergencyContacts;
    if (!$scope.showEditEmergencyContacts) {
      $scope.emergencyContactsErrorMessages = validateEmergencyContacts();
      if ($scope.emergencyContactsErrorMessages.length > 0) {
        $scope.showEditEmergencyContacts = true;
        const modal = mfModal.create({
          variant: "danger",
          subject: "Error",
          message: $scope.emergencyContactsErrorMessages.join("\n"),
          layoutOrder: ["message"],
          confirmLabel: "Ok",
          hideCancelButton: true,
          onComplete: () => modal.close()
        });
      } else {
        $scope.updatePro("emergencyContacts");
      }
    }
  };

  const validateEmergencyContacts = () => {
    const errorMessages = [];
    $scope.profile.emergencyContacts.forEach((contact, index) => {
      if (!contact.name || contact.name.trim() === "") {
        errorMessages.push(`Missing or invalid emergency contact #${index + 1}: name`);
      }
      const phone1 = contact.phone1;
      const phone2 = contact.phone2;
      if (!phone1 || phone1.trim() === "" || !phoneUtils.isValidNumber(phone1)) {
        errorMessages.push(`Missing or invalid emergency contact #${index + 1}: phone #1`);
      }
      if (phone2 === undefined || (phone2 !== "" && phone2.trim() !== "" && !phoneUtils.isValidNumber(phone2))) {
        errorMessages.push(`Invalid emergency contact #${index + 1}: phone #2`);
      }
    });
    return errorMessages;
  };

  $scope.addEmergencyContact = () => {
    if ($scope.profile.emergencyContacts === undefined || $scope.profile.emergencyContacts === null) {
      $scope.profile.emergencyContacts = [];
    }
    $scope.profile.emergencyContacts.push({
      name: "",
      relationship: "",
      livesWithPatient: undefined,
      phone1: "",
      phone2: "",
      address: ""
    });
  };

  $scope.removeEmergencyContact = (idx) => {
    $scope.profile.emergencyContacts.splice(idx, 1);
  }

  function mapCaregiverPhoneNumbers () {
    $scope.profile.phoneNumbers.forEach(x => {
      if (x.phoneNumber) {
        x.phoneNumber = x.phoneNumber.replace(/\+1/g, "");
      }
    });
    $scope.profile.emergencyContacts = ($scope.profile.emergencyContacts || []).map(contact => {
      if (contact.phone1) {
        contact.phone1 = contact.phone1.replace(/\+1/g, "");
      }
      if (contact.phone2) {
        contact.phone2 = contact.phone2.replace(/\+1/g, "");
      }
      return contact;
    });
  }

  $scope.setAddressEmergency = (address, contactIdx) => {
    if (address && $scope.profile.emergencyContacts && $scope.profile.emergencyContacts[contactIdx]) {
      $scope.profile.emergencyContacts[contactIdx].address = address.formatted_address;
    } else if ($scope.profile.emergencyContact && $scope.profile.emergencyContacts[contactIdx]) {
      $scope.profile.emergencyContacts[contactIdx].address = null;
    }
  };
  ///////////////////////
};
