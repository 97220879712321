import { LocalDate, YearMonth } from "js-joda";
import moment from "moment";
import $ from "jquery";

//! @ngInject
export function professionalsCtrl(
  $scope,
  $rootScope,
  Socket,
  NgTableParams,
  $state,
  pros,
  $http,
  toaster,
  DatabaseApi,
  $filter,
  $uibModal,
  Analytics,
  Consts,
  Storage,
  $timeout,
  $window,
  SweetAlert,
  entityNotesModalService,
  generalUtils,
  $location,
  offices,
  $q,
  FilterUtils,
  walkthroughsService,
  wildcard,
) {
  $scope.readyData = false;
  $scope.bundleDataNew = false;

  $scope.redirectDownloadBoost = function () {
    Analytics.event("Redirect download boost", { from: "professionals" });
    $state.go("app.communication", { view: "sendDownload" });
  };

  window.scrollTo(0, 0);
  $scope.agencyCustomFields = [];
  $scope.showOnlyCompletedGroups = { val: false };

  $scope.officesComponentOptions = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: '250px',
    enableSearch: true
  };

  $scope.dateRangeOptions = {
    ranges: {
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 14 Days": [moment().subtract(13, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "Last Year": [
        moment().subtract(1, "year").startOf("year"),
        moment().subtract(1, "year").endOf("year"),
      ],
      "This Year": [moment().startOf("year"), moment().endOf("year")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
    },
    alwaysShowCalendars: true,
    applyClass: "btn-primary",
    locale: {
      direction: "ltr date-range-picker-v2",
      format: "D MMM YY",
    },
    autoApply: true,
    minDate: new Date("2001-01-01"),
  };


  $scope.agencyBranchesComponentOptions = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: '250px',
    enableSearch: true
  };

  $scope.videoTitles = [];
  var orientationTableData = [];
  var generalOrientationData = null;
  var generalOrientationDataMap = {};

  $scope.months = ["2018-12"];
  var thisMonth = YearMonth.now().toString();
  while ($scope.months[0] !== thisMonth) {
    var lastMonth = $scope.months[0];
    $scope.months.unshift(
      YearMonth.parseString(lastMonth)
        .plusMonths(1)
        .toString()
    );
  }

  $scope.zipping = false;

  function getSelectedCaregiversForBoost() {
    console.log("click boost");
    console.log(orientationTableData);
    var ddd = parseCaregiversOrientation(
      lastFetchedCaregiversOrientation,
      true
    );
    return ddd
      .filter(function (item) {
        return !item.eligble;
      })
      .map(function (item) {
        return item.id;
      });
  }

  $scope.boostModal = function () {
    var PRICE_PER_SMS = Math.ceil(
      Consts.SEGMENT_AMOUNT * Consts.PRICE_PER_SEGMENT
    );

    Analytics.event("Boost clicked", { type: "TRAINING_BOOST" });

    var selectedIdsForBoost = getSelectedCaregiversForBoost();

    var modalInstance = $uibModal.open({
      templateUrl: "admin/views/boost-modal.html",
      size: "lg",
      controller: "boostModalCtrl",
      resolve: {
        textAmountToBroadcast: function () {
          return selectedIdsForBoost.length;
        },
        priceOverallCents: function () {
          return selectedIdsForBoost.length * PRICE_PER_SMS;
        },
        type: function () {
          return "TRAINING_BOOST";
        }
      },
      windowTopClass: "boost-modal"
    });
    modalInstance.result.then(function (res) {
      if (res === "BOOST" && selectedIdsForBoost.length) {
        DatabaseApi.post(
          "agency/" +
          $rootScope.agencyId +
          "/" +
          $rootScope.agencyMemberId +
          "/boost",
          {
            caregiverIds: selectedIdsForBoost,
            price: PRICE_PER_SMS,
            type: "TRAINING_BOOST",
            visit: null
          }
        )
          .then(function (result) {
            Analytics.event("Boost done", {
              type: "TRAINING_BOOST",
              boostSentAmount: selectedIdsForBoost.length,
              pricePerSms: PRICE_PER_SMS
            });
            toaster.pop(
              "success",
              "Boosted successfully",
              "Allow 10 minutes for sms to be sent"
            );
          })
          .catch(function (err) {
            toaster.pop(
              "error",
              "Boost failed",
              err.data.error ? err.data.error : "Please try again."
            );
          });
      }
    });
  };

  $scope.getIncompleteHRWithSectionStages = function () {
    DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/hr_section_stages').then(function (res) {
      const defaultStageOptions = [{ id: -2, label: "All stages", order: -2 }, { id: -1, label: "Pending for Review", order: -1 }];

      $scope.stages = {};
      $scope.certifications.forEach(certification => {
        $scope.stages[certification.label] = defaultStageOptions;
      });

      Object.keys(res.data.sectionStages).forEach(function (cert) {
        if (!$scope.stages[cert]) {
          return;
        }
        $scope.stages[cert] = $scope.stages[cert].concat(res.data.sectionStages[cert]);
        $scope.stages[cert].sort(function (a, b) { return a.order < b.order ? -1 : 1 });
      });
      $scope.incompleteHRGet();
    }, function (err) {
      toaster.pop('error', 'Failed to load HR stages');
    });
  }

  $scope.getProgressbarShrinkerPercentageWidth = function (percentage) {
    var val = 100 - percentage;
    return val + '%';
  }

  const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
  $scope.certifications = activeAgencyCertifications
    .map((certificationItem, index) => ({
      id: index,
      label: certificationItem.certification
    }));

  $scope.offices = offices
    .filter(office => office.active)
    .map(office => ({
      id: office.id,
      label: office.name
    }));

  $scope.offices.unshift({ id: -1, label: "No/Inactive Offices" });

  const agencyBranches = DatabaseApi.agencyBranches() || [];
  $scope.agencyBranches = agencyBranches.map(branch => ({
    id: branch.id,
    label: branch.name
  }))

  $scope.addCaregiver = function () {
    $state.go("app.addCaregiver");
  };

  $scope.switchView = function (active) {
    var items = [
      "upload",
      "pending",
      "incompleteHR"
    ];
    items.forEach(function (item) {
      if (active === item) $scope[item] = true;
      else $scope[item] = false;
    });

    if (active === "upload") {
      getHistory();
    }
  };

  var savedTableArr = [];

  var columns = Storage.getObject("caregiversTableSettingsArray");
  var columnsTraining = Storage.getObject("trainingTableSettings");
  let caregiverCodeIdx = -1;
  let caregiverStatusIdx = -1;
  let caregiverCreatedAtIdx = -1;
  let caregiverCreatedByIdx = -1;

  if (columns && columns.length) {
    $scope.tableColumnsLength = 0;
    columns.forEach(function (t, idx) {
      if (t.field === "Code") caregiverCodeIdx = idx;
      if (t.field === "Created At") caregiverCreatedAtIdx = idx;
      if (t.field === "Created By") caregiverCreatedByIdx = idx;
      if (t.field === "Status") caregiverStatusIdx = idx;
      if (t.show) $scope.tableColumnsLength++;
    });

    if (caregiverCodeIdx > -1) {
      let codeColumn = columns[caregiverCodeIdx];
      codeColumn.filter = { code: 'text' };
      columns[caregiverCodeIdx] = codeColumn;
    }

    if (caregiverCreatedAtIdx === -1) {
      columns.splice(caregiverStatusIdx + 1, 0, { field: "Created At", title: "Created At", show: true, togglable: true, sortable: "agencyAssocCreatedAt" });
    }

    if (caregiverCreatedByIdx === -1) {
      columns.splice(caregiverCreatedAtIdx + 1, 0, { field: "Created By", title: "Created By", show: true, togglable: true, sortable: "createdByName" });
    }

    $scope.tableColumns = columns;
  } else {
    //console.log('no columns');
    $scope.tableColumns = [
      { field: "Photo", title: "Photo", show: true, togglable: true },
      { field: "Actions", title: "Actions", show: true, togglable: false},
      { field: "Name", title: "Name", show: true, togglable: true, filter: { displayName: 'text' }, sortable: "displayName"},
      { field: "ID", title: "ID", show: true, togglable: true, filter: { displayId: 'text' }, sortable: "displayId" },
      { field: "Code", title: "Code", show: false, togglable: true, sortable: "code", filter: { code: 'text' } },
      { field: "Branch", title: "Branches", show: false, togglable: true },
      { field: "Gender", title: "Gender", show: false, togglable: true },
      { field: "Birthdate", title: "Birthdate", show: false, togglable: true, sortable: "Birthdate" },
      { field: "Languages", title: "Languages", show: false, togglable: true, sortable: "languages" },
      { field: "Certifications", title: "Type", show: true, togglable: true, sortable: "certifications" },
      { field: "Installation", title: "Installation", show: true, togglable: true, sortable: "confirmed" },
      { field: "Offices", title: "Offices", show: true, togglable: true },
      { field: "Installed At", title: "Installed At", show: false, togglable: true, sortable: "appInstalledAt" },
      { field: "Un Installed At", title: "Un-Installed At", show: false, togglable: true, sortable: "appUninstalledAt" },
      { field: "Seen", title: "Last Seen", show: true, togglable: true, sortable: "lastSeen" },
      { field: "Address", title: "Address", show: true, togglable: true, filter: { address: 'text' }, sortable: "address" },
      { field: "Phone", title: "Phone", show: true, togglable: true, filter: { __phoneNumbers: 'text' } },
      { field: "Status", title: "Status", show: true, togglable: true, sortable: "status" },
      { field: "Created At", title: "Created At", show: true, togglable: true, sortable: "agencyAssocCreatedAt" },
      { field: "Created By", title: "Created By", show: true, togglable: true, sortable: "createdByName" },
      { field: "Hire Date", title: "Hire Date", show: false, togglable: true, sortable: "hireDate" },
      { field: "Work Area Preference", title: "Area", show: false, togglable: true, sortable: "workAreaPreference" },
      { field: "State Registry Number", title: "State Registry Number", show: false, togglable: true, sortable: "stateRegistryNum" },
      { field: "Termination Date", title: "Termination Date", show: false, togglable: true, sortable: "terminationDate" },
    ];
    if ($rootScope.isOnboardingAgency) {
      $scope.tableColumns.push({
        field: "Medflyt CG",
        title: "Medflyt CG",
        show: true,
        togglable: true,
        sortable: "isFromLandingPage",
      });
    }
    $scope.tableColumnsLength = 0;
    $scope.tableColumns.forEach(function (t) {
      if (t.show) $scope.tableColumnsLength++;
    });
  }
  $scope.$watch(
    "tableColumns",
    function () {
      if ($scope.tableColumns) {
        Storage.setObject("caregiversTableSettingsArray", $scope.tableColumns);
        $scope.tableColumnsLength = 0;
        $scope.tableColumns.forEach(function (t) {
          if (t.show) {
            $scope.tableColumnsLength++
          };
        });
        initTable(prosArr);
      }
    },
    true
  );

  function initCustomFieldTableColumns() {
    if ($scope.agencyCustomFields.length) {
      for (const customField of $scope.agencyCustomFields) {
        // Add agency custom field to table column when not exists..
        if (customField.addToSettings && $scope.tableColumns.find((column) => column.field === "customField" && column.title === customField.fieldName) === undefined) {
          $scope.tableColumns.push({ field: "customField", show: false, title: customField.fieldName, togglable: true, sortable: `customFields.${[customField.fieldName]}` });
        }
      }
      $scope.tableColumnsLength = 1;
      $scope.tableColumns.forEach(function (t) {
        if (t.show) $scope.tableColumnsLength++;
      });
    }
  }

  $scope.datePopups = {};

  $scope.obj = { disable: false };
  $scope.pendingToggle = { status: "Show" };
  // get upload history david
  function getHistory() {
    DatabaseApi.get(
      "agencies/" + $rootScope.agencyId + "/table_file_upload"
    ).then(
      function (response) {
        var history = [];
        response.data.list.forEach(function (line) {
          if (line.type === "CAREGIVER") history.push(line);
        });
        $scope.history = history;
      },
      function (response) { }
    );
  }

  var prosArr = [];

  $scope.$watch(
    function () {
      return $rootScope.pendingCaregivers;
    },
    function () {
      if ($rootScope.pendingCaregivers) {
        $scope.pendingArr = $rootScope.pendingCaregivers;
        initPendingTable();
      }
    },
    true
  );

  //   resetPageTabIndications();
  $scope.refreshCache = false;

  $scope.file = null;

  function loadProfessionalsData() {
    switch ($state.current.name) {
      case "app.professionals.pendingApplication": {
        $scope.getIncompleteHRWithSectionStages();
        break;
      }
      default:
        break;
    }
  }

  var prosMap = DatabaseApi.caregivers() || {};
  loadProfessionalsData();

  $rootScope.$on("got_data", function (event) {
    if ($scope.gotData) return;
    $scope.gotData = true;
    prosMap = DatabaseApi.caregivers() || {};
    init();
    loadProfessionalsData();
  });
  $rootScope.$on("caregiver_changed", function (event) {
    prosMap = DatabaseApi.caregivers() || {};
    init();
    initPendingTable();
  });
  $rootScope.$on("new_caregiver", function (event) {
    prosMap = DatabaseApi.caregivers() || {};
    init();
  });

  $scope.pendingArr = DatabaseApi.getPendingCaregivers();
  //$scope.pending = DatabaseApi.getPendingCaregivers('agencies/' + $rootScope.agencyId + '/caregivers/pending').then(function(res){
  //console.log(res.data);

  function initPendingTable() {
    const oldTotal = $scope.pendingTable?.total?.() || 0;
    $scope.pendingArr = DatabaseApi.getPendingCaregivers();

    if (!$scope.pendingArr) return;
    //console.log($scope.pendingArr);

    var sorting = { timeStamp: "desc" };
    var page = null;
    if ($scope.pendingTable) {
      sorting = $scope.pendingTable.sorting();
      page = $scope.pendingTable.page();
    }

    var options = {
      count: 25,
      sorting: sorting
    };
    $scope.pendingTable = new NgTableParams(options, {
      counts: [],
      dataset: $scope.pendingArr
    });
    if (page && oldTotal === $scope.pendingTable.total()) $scope.pendingTable.page(page);
  }

  if ($scope.pendingArr) initPendingTable();
  //}, function(err){
  //  toaster.pop('error', "Something Went Wrong", 'Error loading pending caregivers');
  //});

  function init() {
    if (
      !prosMap ||
      ($state.current.name !== "app.professionals" &&
        $state.current.name !== "app.professionals.caregivers" &&
        $state.current.name !== "app.professionals.pendingAcceptance")
    )
      return;

    if ($scope.obj.disable) return;
    //console.log('aa');

    $scope.pendingArr = DatabaseApi.getPendingCaregivers();
    //$scope.pending = DatabaseApi.getPendingCaregivers('agencies/' + $rootScope.agencyId + '/caregivers/pending').then(function(res){
    //console.log(res.data);

    const oldTotal = $scope.pendingTable?.total?.() || 0;
    var sorting = { timeStamp: "desc" };
    var page = null;
    if ($scope.pendingTable) {
      sorting = $scope.pendingTable.sorting();
      page = $scope.pendingTable.page();
    }

    var options = {
      count: 25,
      sorting: sorting
    };
    if ($scope.pendingArr && $scope.pendingArr.length) {
      $scope.pendingTable = new NgTableParams(options, {
        counts: [],
        dataset: $scope.pendingArr
      });
      if (page && oldTotal === $scope.pendingTable.total()) $scope.pendingTable.page(page);
    }

    var proLength = Object.keys(prosMap).length;
    if (proLength) {
      prosArr = [];
      var percent = 0;
      const agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
      for (var key in prosMap) {
        if (prosMap[key].appInstalled) {
          //console.log(pro.id);
          percent++;
          prosMap[key].confirmed = "installed";
        } else if (prosMap[key].appInstalledDate) {
          prosMap[key].confirmed = "uninstalled";
        } else {
          prosMap[key].confirmed = "notinstalled";
        }
        if (!prosMap[key].lastSeen) prosMap[key].lastSeen = -1;
        prosMap[key].statusClass = $scope.getStatusByValue(prosMap[key].status).statusClass;
        if (prosMap[key].agencyAssocCreatedByCaregiverId !== null) {
          prosMap[key].createdByName = prosMap[prosMap[key].agencyAssocCreatedByCaregiverId]?.displayName;
        }
        else if (prosMap[key].agencyAssocCreatedByAgencyMemberId !== null){
          prosMap[key].createdByName = agencyMembersNames[prosMap[key].agencyAssocCreatedByAgencyMemberId];
        }
        prosArr.push({
          id: prosMap[key].id,
          displayId: prosMap[key].displayId,
          address: prosMap[key].address,
          displayName: prosMap[key].displayName,
          certifications: prosMap[key].certifications,
          confirmed: prosMap[key].confirmed,
          createdAt: prosMap[key].createdAt,
          lastSeen: prosMap[key].lastSeen,
          online: prosMap[key].online,
          phoneNumber: prosMap[key].phoneNumber,
          phoneNumbers: prosMap[key].phoneNumbers,
          photoUrl: prosMap[key].photoUrl,
          status: prosMap[key].status,
          statusClass: prosMap[key].statusClass,
          appInstalledDate: prosMap[key].appInstalledDate,
          appInstalledAt: prosMap[key].appInstalledAt,
          appUninstalledAt: prosMap[key].appUninstalledAt,
          appInstalled: prosMap[key].appInstalled,
          languages: prosMap[key].languages,
          gender: prosMap[key].gender,
          branch: prosMap[key].caregiverBranch,
          birthDate: prosMap[key].birthDate,
          code: prosMap[key].caregiverCode,
          hireDate: prosMap[key].hireDate,
          workAreaPreference: prosMap[key].workAreaPreference,
          stateRegistryNum: prosMap[key].stateRegistryNum,
          officeIds: prosMap[key].officeIds,
          branchIds: prosMap[key].branchIds,
          customFields: prosMap[key].customFields,
          terminationDate: prosMap[key].terminationDate,
          agencyAssocCreatedAt: prosMap[key].agencyAssocCreatedAt,
          createdByName: prosMap[key].createdByName,
          isFromLandingPage: prosMap[key].isFromLandingPage
        });
      }
      $scope.confirmedPercent = (percent / proLength) * 100;

      var notInstalled = proLength - percent;
      var notPercent = 100 - $scope.confirmedPercent;

      var installLabel =
        "Installed App - " +
        parseInt($scope.confirmedPercent) +
        "% (" +
        percent +
        "/" +
        proLength +
        ")";
      var notInstallLabel =
        "Not installed app - " +
        parseInt(notPercent) +
        "% (" +
        notInstalled +
        "/" +
        proLength +
        ")";

      $scope.barValues = [
        {
          value: $scope.confirmedPercent,
          label: installLabel,
          type: "success"
        },
        { value: notPercent, label: notInstallLabel, type: "danger" }
      ];
      initTable(prosArr);
    }
    // Removed DEC 2020 due to new notes feature
    // getWalkthroughs();

    // Load filters
    const storageFilters = FilterUtils.rootFilters.professionalsCtrl;
    if (storageFilters !== undefined) {
      $scope.filters = storageFilters;
    }

    const agencyCustomFields = DatabaseApi.agencyCustomFields();
    $scope.agencyCustomFields = angular.copy(
      agencyCustomFields.filter(
        (customField) =>
          customField.addToSettings && customField.entityName === "CAREGIVER"
      )
    );
    initCustomFieldTableColumns();
  }

  $scope.$on("$destroy", function () {
    const filters = $scope.filters;
    FilterUtils.rootFilters.professionalsCtrl = angular.copy(filters);
  });

  $scope.languages = [
    { id: 1, label: "English" },
    { id: 2, label: "Spanish" },
    { id: 3, label: "Chinese" },
    { id: 4, label: "Portuguese" },
    { id: 5, label: "French" },
    { id: 6, label: "Russian" },
    { id: 7, label: "Arabic" },
  ];

  $scope.statuses = [
    { id: 1, label: "Active", value: "ACTIVE", text: "Active", statusClass: "green" },
    { id: 2, label: "On Hold", value: "ON_HOLD", text: "On Hold", statusClass: "yellow" },
    { id: 3, label: "On Leave", value: "ON_LEAVE", text: "On Leave", statusClass: "orange" },
    { id: 4, label: "Pending Application", value: "PENDING", text: "Pending Application", statusClass: "lightblue" },
    { id: 5, label: "Inactive", value: "SUSPENDED", text: "Inactive", statusClass: "azur" },
    { id: 6, label: "Terminated", value: "TERMINATED", text: "Terminated", statusClass: "red" },
    { id: 7, label: "Quit", value: "QUIT", text: "Quit", statusClass: "azur" },
    { id: 8, label: "Rejected", value: "REJECTED", text: "Rejected", statusClass: "red" }
  ];

  $scope.mobileInstallations = [
    { id: 1, label: "Installed", value: "installed" },
    { id: 2, label: "Un-Installed", value: "uninstalled" },
    { id: 3, label: "Not Installed", value: "notinstalled" }
  ];

  $scope.filters = {
    certifications: [],
    statuses: [{ id: ($scope.statuses.find(stat => stat.value === "ACTIVE").id) }],
    languages: [],
    offices: [],
    agencyBranches: [],
    installation: {},
    from: null,
    to: null,
    still: false,
    hireDate: {
      startDate: null,
      endDate: null,
    },
  };

  $scope.singleSelectionExtraSettings = {
    styleActive: true,
    singleSelection: true,
    selectionLimit: 1,
    smartButtonMaxItems: 1
  };

  $scope.stageSingleSelectionExtraSettings = {
    styleActive: true,
    singleSelection: true,
    selectionLimit: 1,
    smartButtonMaxItems: 1,
    displayProp: 'labelWithCount'
  };

  $scope.removeHireDateFilter = function () {
    $scope.filters.hireDate = {
      startDate: null,
      endDate: null,
    };
  }

  // agency member offices added to default filters
  if ($rootScope.user.agencyMember.officeIds) {
    $scope.filters.offices = $rootScope.user.agencyMember.officeIds.map(function (officeId) {
      return $scope.offices.find(function (obj) { return obj.id === officeId; });
    }).filter(office => office !== undefined);
  }

  $scope.getStatusByValue = function (statusValue) {
    return $scope.statuses.find(function (stat) {
      return stat.value === statusValue;
    });
  }

  $scope.showStatus = function (row) {
    var selected = $filter("filter")($scope.statuses, { value: row.status });
    return row.status && selected.length ? selected[0].text : "Not set";
  };

  $scope.removeRequest = function (row, i) {
    SweetAlert.swal(
      {
        title: "Remove Request?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, Remove",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          if (row.status !== "JOIN_REQUEST") return;
          var url =
            "agencies/" +
            $rootScope.agencyId +
            "/caregivers/" +
            row.id +
            "/remove_join_request";
          DatabaseApi.delete(url).then(
            function (res) {
              DatabaseApi.reloadPendingCaregivers(function () {
                $scope.pendingArr.splice(i, 1);
                initPendingTable();
              });
            },
            function (err) {
              toaster.pop("error", "Something went wrong");
            }
          );
        }
      }
    );
  };

  $scope.openPopup = function (row) {
    var newScope = $scope.$new();
    newScope.offices = offices;
    var modalInstance = $uibModal.open({
      templateUrl: "admin/views/status-modal.html",
      size: "md",
      scope: newScope,
      controller: "approveCaregiverModalCtrl",
      resolve: {
        statuses: function () {
          return $scope.statuses;
        },
        caregiver: function () {
          return row;
        },
        certifications: () => $scope.certifications
      }
    });
    modalInstance.result.then(
      function (res) {
        console.log(res);
        if (res === "changed") {
          DatabaseApi.reloadPendingCaregivers();
          $timeout(function () {
            $state.reload();
          }, 500);
        }
      },
      function () { }
    );
  };

  //DatabaseApi.patch('caregiver/52', {firstName: 'Old'});

  $scope.type = function (value) {
    var type;
    if (value < 25) {
      type = "danger";
    } else if (value < 51) {
      type = "warning";
    } else if (value < 75) {
      type = "info";
    } else {
      type = "success";
    }
    return type;
  };

  $scope.goToProfile = function (id) {
    if (!$rootScope.user.permissions.includes("view_caregiver_profile")) {
      toaster.pop('warning','No permission to see caregiver\'s profile');
      return;
    }
    $rootScope.openCaregiverModal(id);
    $rootScope.caregiverModalInstance.result.then(function (res) {
      DatabaseApi.caregivers(true);
      $rootScope.$broadcast('caregiver_changed');
    }, function () {
      DatabaseApi.caregivers(true);
      $rootScope.$broadcast('caregiver_changed');
    });
  };

  $scope.applyGlobalSearch = function (term) {
    var filter = { $: term };
    if ($scope.table) {
      angular.extend($scope.table.filter(), filter);
    } else {
      console.log("no table");
    }
  };

  $scope.applyGlobalSearchTraining = function (term) {
    var filter = { $: term };
    if ($scope.orientationOverviewTable) {
      angular.extend($scope.orientationOverviewTable.filter(), filter);
    } else {
      console.log("no table");
    }
  };

  $scope.handleNotesModalOpen = function (profileId, profileName) {
    entityNotesModalService.handleNotesModalOpen({ profileId, profileName });
  }

  $scope.sendApplicationLinkSMS = function (caregiverID) {
    SweetAlert.swal(
      {
        title: "Send SMS?",
        text: "Are you sure you want to send the application link SMS?",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3077EB",
        confirmButtonText: "Yes, send",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          var url =
            "agencies/" +
            $rootScope.agencyId +
            "/agency_member/" +
            $rootScope.agencyMemberId +
            "/caregivers/" +
            caregiverID +
            "/send_application_link_sms"
          DatabaseApi.put(url).then(
            function (res) {
              toaster.pop("info", "SMS sent");
            },
            function (err) {
              toaster.pop("error", "Couldn't send the SMS. Please contact support");
            }
          );
        }
      }
    );
  };

  function initTable(data) {
    data = applyFilters(data);

    if (!data) return;
    var isArray = false;
    try {
      isArray = Array.isArray(data);
    } catch (e) { }

    //console.log(isArray);
    if (!isArray) return;

    const oldTotal = $scope.table?.total?.() || 0;
    var page = false,
      filters = undefined,
      sorting = { lastSeen: "desc", agencyAssocCreatedAt: "desc" };
    if ($scope.table) {
      filters = $scope.table.filter();
      sorting = $scope.table.sorting();
      //$scope.tableOptions.filter = $scope.table.filter();
      page = $scope.table.page();
    }

    var options = {
      count: 25,
      sorting: sorting
    };
    if (filters) options.filter = filters;

    $scope.table = new NgTableParams(options, {
      counts: [],
      dataset: data
    });
    if (page && oldTotal === $scope.table.total()) $scope.table.page(page);
    //console.log($scope.table);
  }

  $scope.setFilters = function () {
    initTable(prosArr);
  };

  $scope.$watch('filters', function () {
    $scope.setFilters();
  }, true);

  $scope.openCalendar = function () {
    $("#date-picker")
      .data("daterangepicker")
      .show();
  };

  $scope.showCaregiverVideos = function (caregiverWithVideos) {
    $rootScope.openVideosModal(caregiverWithVideos);
  };

  $scope.showCaregiverOffices = function (officeIds) {
    let presentedOffices;
    const includeInactiveOffices = $scope.filters.offices.find(office => office.id === -1) !== undefined;
    if (includeInactiveOffices) {
      presentedOffices = officeIds
        .map(officeId => offices.find(office => office.id === officeId))
        .filter(office => office !== undefined)
        .map(office => office.name)
    } else {
      presentedOffices = officeIds
        .map(officeId => $scope.offices.find(office => office.id === officeId))
        .filter(office => office !== undefined)
        .map(office => office.label)
    }

    return presentedOffices.join(", ");
  }


  $scope.showCaregiverAgencyBranches = function (branchIds) {
    return branchIds
      .map((branchId) =>
        $scope.agencyBranches.find((branch) => branch.id === branchId)
      )
      .filter((branch) => branch !== undefined)
      .map((branch) => branch.label)
      .join(", ");
  };

  var lastFetchedCaregiversOrientation = null;

  $scope.caregiverStatesData = $scope.statuses.map(({ value, text }) => ({
    id: value,
    label: text
  }));

  function applyFilters(data) {
    var selectedCertifications = $scope.filters.certifications.map(function (obj) {
      return $scope.certifications.find(function (cert) { return cert.id === obj.id; }).label;
    });
    var selectedLanguages = $scope.filters.languages.map(function (obj) {
      return $scope.languages.find(function (lang) { return lang.id === obj.id; }).label;
    });
    var selectedStatuses = $scope.filters.statuses.map(function (obj) {
      return $scope.statuses.find(function (stat) { return stat.id === obj.id; }).value;
    });

    var selectedOffices = $scope.filters.offices
      .map((obj) => $scope.offices.find(office => office.id === obj.id).id);

    const selectedBranchIds = $scope.filters.agencyBranches.map(
      (branchObject) =>
        $scope.agencyBranches.find((branch) => branch.id === branchObject.id).id
    );

    data = data.filter(function (c) {
      if (selectedCertifications.length > 0 && !c.certifications.some(cert => selectedCertifications.includes(cert))) {
        return false;
      }
      if (selectedLanguages.length > 0 && c.languages.find(function (lang) { return selectedLanguages.indexOf(lang) !== -1; }) === undefined) {
        return false;
      }
      if (selectedStatuses.length > 0 && selectedStatuses.indexOf(c.status) === -1) {
        return false;
      }
      if (
        selectedBranchIds.length > 0 &&
        c.branchIds.find(function (branchId) {
          return selectedBranchIds.indexOf(branchId) !== -1;
        }) === undefined
      ) {
        return false;
      }
      if (selectedBranchIds.length > 0 && c.branchIds !== undefined && c.branchIds.find(branchId => {return selectedBranchIds.indexOf(branchId) !== -1; }) === undefined) {
        return false;
      }
      if ($scope.filters.hireDate.startDate !== null && $scope.filters.hireDate.endDate !== null) {
        if (
            moment(c.hireDate).isBefore(moment($scope.filters.hireDate.startDate)) ||
            moment(c.hireDate).isAfter(moment($scope.filters.hireDate.endDate)) ||
            c.hireDate === null
        ) {
          return false;
        }
      }
      if (selectedOffices.length > 0 && c.officeIds.find(function (office) { return selectedOffices.indexOf(office) !== -1; }) === undefined) {
        const caregiverHasInactiveOffices = c.officeIds.some(officeId => {
          return offices.find(office => office.id === officeId).active === false;
        });
        if (selectedOffices.indexOf(-1) !== -1 && (c.officeIds.length === 0 || caregiverHasInactiveOffices)) {
          return true;
        }
        return false;
      }
      return true;
    });

    var arr = [];

    data.forEach(function (c) {
      var filterOut = false;

      if ($scope.filters.installation.length !== {}) {
        let selectedInstallation = $scope.mobileInstallations.find(i => i.id === $scope.filters.installation.id);
        selectedInstallation = selectedInstallation === undefined ? "" : selectedInstallation.value;
        // installed
        if (selectedInstallation === "installed") {
          if ($scope.filters.from || $scope.filters.to) {
            if ($scope.filters.still && !c.appInstalled) filterOut = true;
            else {
              var dates = c.appInstalledAt;

              if ($scope.filters.from) {
                var from = JSON.stringify($scope.filters.from).slice(1, -1);
                var isOkFrom;
                dates.forEach(function (d) {
                  if (d >= from) isOkFrom = true;
                });
                if (!isOkFrom) filterOut = true;
              }

              if ($scope.filters.to) {
                var to = JSON.stringify($scope.filters.to).slice(1, -1);
                var isOkTo;
                dates.forEach(function (d) {
                  if (d <= to) isOkTo = true;
                });
                if (!isOkTo) filterOut = true;
              }
            }
          } else if (!c.appInstalled) {
            filterOut = true;
          }

          // uninstalled
        } else if (selectedInstallation === "uninstalled") {
          if ($scope.filters.from || $scope.filters.to) {
            if ($scope.filters.still && c.appInstalled) filterOut = true;
            else {
              var dates = c.appUninstalledAt;

              if ($scope.filters.from) {
                var from = JSON.stringify($scope.filters.from).slice(1, -1);
                var isOkFrom;
                dates.forEach(function (d) {
                  if (d >= from) isOkFrom = true;
                });
                if (!isOkFrom) filterOut = true;
              }

              if ($scope.filters.to) {
                var to = JSON.stringify($scope.filters.to).slice(1, -1);
                var isOkTo;
                dates.forEach(function (d) {
                  if (d <= to) isOkTo = true;
                });
                if (!isOkTo) filterOut = true;
              }
            }
          } else if (c.appInstalled || !c.appUninstalledAt.length) {
            filterOut = true;
          }

          // not installed
        } else if (selectedInstallation === "notinstalled") {
          if (c.appInstalled || c.appInstalledAt.length) filterOut = true;
        }
      }
      //console.log('filterOut', filterOut);

      if (!filterOut) arr.push(c);

      c.__phoneNumbers = c.phoneNumbers.map(x => x.phoneNumber).join();
    });

    savedTableArr = arr;
    return arr;
  }

  $scope.exportTable = function () {
    const rows = [];
    const tableTitles = 
      $scope.tableColumns
        .filter(
          (col) =>
            col.show && !["Photo", "Actions"].includes(col.field)
        )
        .map((col) => col.title);

    rows.push(tableTitles);

    // Filter table data by global filter
    const filteredTable = $filter('filter')(savedTableArr, $scope.globalFilter);
    filteredTable.forEach((caregiver) => {
      const row = [];
      const customFieldNames = Object.keys(caregiver.customFields);
      let string;
      tableTitles.forEach((title) => {
        if (customFieldNames.includes(title)) {
          const customField = caregiver.customFields[title];
          if (customField !== undefined) {
            switch (customField.fieldType) {
              case "PATIENT":
                row.push('"' + customField.value.displayName + '"');
                break;
              case "CAREGIVER":
                row.push('"' + customField.value.displayName + '"');
                break;
              case "DATE_RANGE":
                row.push('"' + $filter("mfShortDate")(customField.from) + ' - ' + $filter("mfShortDate")(customField.to) + '"');
                break;
              case "DATE":
                row.push('"' + $filter("mfShortDate")(customField.value) + '"');
                break;
              case "PHONE_NUMBER":
                row.push('"' + customField.value.replace('+1', '') + '"');
                break;
              case "BOOLEAN":
                row.push('"' + (customField.value === true ? 'Yes' : 'No') + '"');
                break;
              default:
                row.push('"' + customField.value + '"');
            }
          }
          else {
            row.push('" "');
          }
        }
        else {
          switch (title) {
            case "ID":
              row.push(caregiver.displayId || "");
              break;
            case "Name":
              row.push(caregiver.displayName || "");
              break;
            case "Code":
              row.push(caregiver.code || "");
              break;
            case "State Registry Number":
              row.push(caregiver.stateRegistryNum || "");
              break;
            case "Branch":
              row.push(caregiver.branch || "");
              break;
            case "Work Area Preference":
              row.push(caregiver.workAreaPreference || "");
              break;
            case "Gender":
              row.push(caregiver.gender || "");
              break;
            case "Birthdate":
              row.push($filter("mfShortDate")(caregiver.birthDate) || "");
              break;
            case "Seen":
              if (caregiver.lastSeen === -1) {
                row.push("");
              } else {
                row.push($filter("mfShortDate")(caregiver.lastSeen) || "");
              }
              break;
            case "Hire Date":
              if (caregiver.hireDate === -1) {
                row.push("");
              } else {
                row.push($filter("mfShortDate")(caregiver.hireDate) || "");
              }
              break;
            case "Languages":
              row.push(caregiver.languages.join(" | ") || "");
              break;
            case "Type":
              row.push(caregiver.certifications.join(" | ") || "");
              break;
            case "Status":
              row.push(caregiver.status || "");
              break;
            case "Address":
              const address = caregiver.address;
              row.push('"' + address + '"' || '" "');
              break;
            case "Installation":
              if (caregiver.confirmed === "installed") row.push("Installed");
              else if (caregiver.confirmed === "uninstalled")
                row.push("Un-Installed");
              else row.push("Not Installed");
              break;
            case "Installed At":
              string = " ";
              caregiver.appInstalledAt.forEach(function (d) {
                if (string !== " ") string += " | ";
                string += $filter("mfShortDate")(d);
              });
              row.push(string);
              break;
            case "Un Installed At":
              string = " ";
              caregiver.appUninstalledAt.forEach(function (d) {
                if (string !== " ") string += " | ";
                string += $filter("mfShortDate")(d);
              });
              row.push(string);
              break;
            case "Phone":
              string = " ";
              caregiver.phoneNumbers.forEach(function (f) {
                if (string !== " ") string += " | ";
                string +=
                  $filter("americanphone")(f.phoneNumber) + " (" + f.type + ")";
              });
              row.push(string);
              break;
            case "Offices":
              let caregiverOfficeNames = caregiver.officeIds.reduce((acc, curr) => {
                const filteredOffice = $scope.offices.filter(x => x.id === curr);
                if (filteredOffice[0] != undefined) {
                  acc.push(filteredOffice[0].label);
                }

                return acc;
              }, []);
              row.push(`"${caregiverOfficeNames.join(`, `)}"`);
              break;
            case "Last Seen":
              row.push(caregiver.lastSeen !== -1 ? caregiver.lastSeen : '');
              break;
            case "Created At":
              row.push('"' + $filter("mfShortTime")(caregiver.agencyAssocCreatedAt, ['withDate']) + '"' || '');              
              break;
            case "Created By":
              row.push('"' + caregiver.createdByName.replace(/(\r\n|\n|\r)/gm, "") + '"' || '');
              break;
            default:
              break;
          }
        }
      });

      rows.push(row);
    });

    let csvContent = "";
    rows.forEach(function (rowArray) {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "medflyt-caregiver-export.csv");

    link.click();
    window.URL.revokeObjectURL(url);
  };

  $scope.exportOrientationTable = function () {
    var list = angular.copy(orientationTableData);

    var titles = ["Name"];

    var bundleName = "";

    if ($scope.orientationForm.type === "watched") {
      titles.push("Time Watched Within Dates(hh:mm:ss)");
    } else {
      if (
        $scope.orientationFilters.filterByBundleId &&
        $scope.orientationFilters.filterByBundleId !== "total"
      ) {
        titles.push("Bundle");
        var bb =
          $scope.bundlesData.bundles[
          $scope.orientationFilters.filterByBundleId
          ];
        if (bb) {
          bundleName = bb.name;
        }
      }
      titles.push("State Registry Number");
      titles.push("Last Due Date");
      titles.push("Next Due Date");
      titles.push("Time Watched (hh:mm:ss)");
      titles.push("Compliance Status");
      titles.push("Certificate");
      titles.push("Certificate Done At");
    }

    var rows = [];
    rows.push(titles);

    list.forEach(function (caregiver) {
      var row = [];
      titles.forEach(function (title) {
        if (title === "Name") row.push(caregiver.displayName || "");
        if (title === "ID") row.push(caregiver.displayId || "");
        if (title === "Bundle") row.push(bundleName);
        else if (title === "Video") row.push(caregiver.videoTitle || "");
        else if (title === "Due Date")
          row.push($filter("mfShortDate")(caregiver.dueDate) || "");
        else if (title === "Time Watched Within Dates(hh:mm:ss)")
          row.push($filter("secondsToTime")(caregiver.secondsWatchedMax) || "");
        else if (title === "Time Watched (hh:mm:ss)")
          row.push($filter("secondsToTime")(caregiver.secondsWatchedMax) || "");
        else if (title === "Last Due Date")
          row.push($filter("mfShortDate")(caregiver.lastDueDate) || "");
        else if (title === "Next Due Date")
          row.push($filter("mfShortDate")(caregiver.nextDueDate) || "");
        else if (title === "Certificate Done At")
          row.push(
            $filter("mfShortDate")(caregiver.bundleCreatedAt) || ""
          );
        else if (title === "Compliance Status")
          row.push(caregiver.compliant ? "Compliant" : "Not Compliant");
        else if (title === "Certificate")
          row.push(caregiver.eligble ? "Eligible" : "Not Eligible");
        else if (title === "State Registry Number") {
          row.push(
            caregiver.stateRegistryNum ? caregiver.stateRegistryNum : ""
          );
        } else if (title === "Code") row.push(caregiver.caregiverCode || "");
        else if (title === "StateRegistryNumber")
          row.push(caregiver.stateRegistryNum || "");
        else if (title === "Branch") row.push(caregiver.branch || "");
        else if (title === "WorkAreaPreference")
          row.push(caregiver.workAreaPreference || "");
        else if (title === "Gender") row.push(caregiver.gender || "");
        else if (title === "Birthdate")
          row.push($filter("mfShortDate")(caregiver.birthDate) || "");
        else if (title === "Seen") {
          if (caregiver.lastSeen === -1) row.push("");
          else
            row.push($filter("mfShortDate")(caregiver.lastSeen) || "");
        } else if (title === "HireDate") {
          if (caregiver.hireDate === -1) row.push("");
          else
            row.push($filter("mfShortDate")(caregiver.hireDate) || "");
        } else if (title === "Languages")
          row.push(caregiver.languages.join(" | ") || "");
        else if (title === "Certification")
          row.push(caregiver.certification || "");
        else if (title === "Status") row.push(caregiver.status || "");
        else if (title === "Address") {
          var address = caregiver.address;
          row.push('"' + address + '"' || '" "');
        } else if (title === "Installation") {
          if (caregiver.confirmed === "installed") row.push("Installed");
          else if (caregiver.confirmed === "uninstalled")
            row.push("Un-Installed");
          else row.push("Not Installed");
        } else if (title === "InstalledAt") {
          var string = " ";
          caregiver.appInstalledAt.forEach(function (d) {
            if (string !== " ") string += " | ";
            string += $filter("mfShortDate")(d);
          });
          row.push(string);
        } else if (title === "UnInstalledAt") {
          var string = " ";
          caregiver.appUninstalledAt.forEach(function (d) {
            if (string !== " ") string += " | ";
            string += $filter("mfShortDate")(d);
          });
          row.push(string);
        } else if (title === "Phone") {
          var string = " ";
          caregiver.phoneNumbers.forEach(function (f) {
            if (string !== " ") string += " | ";
            string +=
              $filter("americanphone")(f.phoneNumber) + " (" + f.type + ")";
          });
          row.push(string);
        } else if (title === "Termination Date")
          row.push($filter("mfShortDate")(caregiver.terminationDate) || "");
      });

      rows.push(row);
    });

    var csvContent = "";
    rows.forEach(function (rowArray) {
      var row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var blob = new Blob([csvContent], { type: "text/csv" }),
      url = window.URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      "medflyt-video-export-" + LocalDate.now().toString() + ".csv"
    );

    link.click();
    window.URL.revokeObjectURL(url);
  };

  function perc2color(perc) {
    var r,
      g,
      b = 0;
    if (perc < 50) {
      r = 229;
      g = Math.round(5.1 * perc);
    } else {
      g = 175;
      r = Math.round(510 - 5.1 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  }

  /*
  if (record.appInstalled) {
        statsCaregivers[record.status.toLowerCase() + 'Installed']++;
        statsCaregivers.ids.installed.push(record.id);
        statsCaregivers.installed++;
        if(record.status.toLowerCase() === 'active') statsCaregivers.ids.activeInstalled.push(record.id);

      } else if (record.appInstalledDate) {
        statsCaregivers[record.status.toLowerCase() + 'Uninstalled']++;
        statsCaregivers.ids.unInstalled.push(record.id);
        statsCaregivers.uninstalled++;
        if(record.status.toLowerCase() === 'active') statsCaregivers.ids.activeUnInstalled.push(record.id);

      } else if(record.status.toLowerCase() === 'active'){
        statsCaregivers.ids.notInstalled.push(record.id);
        if(record.status.toLowerCase() === 'active') statsCaregivers.ids.activeNotInstalled.push(record.id);
      }
  */

  $scope.$watch("file", function () {
    if ($scope.file && $scope.file.name) {
      var fileType = $scope.file.name.split(".");
      fileType = fileType[fileType.length - 1];
      fileType = fileType.toLowerCase();
      if (fileType === "xls" || fileType === "xlsx") {
        fileUpload();
      } else {
        toaster.pop(
          "warning",
          "Unsupported file type",
          "Please uplaod an Excel file (.xls, .xlsx)"
        );
        $scope.file = null;
      }
    }
  });

  function fileUpload() {

    var formData = new FormData();
    formData.append("file", $scope.file, $scope.file.name);

    formData.append("type", "CAREGIVER");
    formData.append("caregiverBranch", "");

    var url =
      Consts.api +
      "agencies/" +
      $rootScope.agencyId +
      "/coordinator/" +
      $rootScope.agencyMemberId +
      "/table_file_upload";
    $http({
      url: url,
      method: "POST",
      data: formData,
      headers: { "Content-Type": undefined }
    }).then(
      function (response) {
        $scope.file = null;
        $scope.fileSent =
          "Thanks for uploading, the file was uploaded successfully.";
        toaster.pop("success", "Success", "File Uploaded");
        getHistory();
      },
      function (response) {
        $scope.file = null;
        $scope.fileSent =
          "An Error Occurred, please refresh the page and try again";
        toaster.pop("error", "Something Went Wrong", "Please try again");
        getHistory();
      }
    );
  }

  var pathArr = $location.$$path.split("/");
  $scope.professionalsPage = "professionals";
  if (pathArr && pathArr.length) {
    $scope.professionalsPage = pathArr[pathArr.length - 1];
    if ($scope.professionalsPage === "pendingApplication") {
      $scope.getIncompleteHRWithSectionStages();
    }
  }


  async function getWalkthroughs() {
    walkthroughsService.handleWalkthroughs('caregiversNotes');
  }

  init();

  $scope.exportTxt = function ({ filename, createdAt, message }) {
    console.log(filename, createdAt, message);
    const blob = new Blob([message], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}-${createdAt}-result.txt`);

    link.click();
    window.URL.revokeObjectURL(url);
  };

  $scope.downloadOriginalFile = function ({ id }) {
    const endpoint = wildcard(
      `agencies/:agencyId/table_file_upload/:uploadJobId/url`,
      $rootScope.agencyId,
      id
    );

    DatabaseApi.get(endpoint)
      .then(({ data: { url } }) => {
        window.open(url);
      })
      .catch(() => toaster.pop("error", "File wasn't found"));
  };
};
