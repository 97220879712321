import { LocalDate, LocalDateTime } from "js-joda";
import { match } from "ts-pattern";
import { ComplianceParser } from "../../parsers/compliance-parser";
import { Rawify } from "../../scripts/@ts-utils/rawify";
import { CaregiverDict } from "../../scripts/messages/caregiver";
import {
  ComplainceRejectDocument,
  ComplianceInstanceCreate,
  CompliancePrioritizedCaregiverByPendingDocuments,
  CompliancePrioritizedCaregiverByPendingDocumentsResponse,
  ComplianceResponse,
  ComplianceStatsResponse,
  ComplianceV2FieldInstance,
  MissingFollowupItemsResponse,
} from "../../scripts/messages/compliance";
import { CaregiverDocumentUploadId, CaregiverId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";
import { fmap } from "../../scripts/utils/generalUtils";

//! @ngInject
export class CompService {
  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService
  ) {}

  fetch = (caregiversMap: CaregiverDict) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/compliance`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<ComplianceResponse>(url).then((res) => {
      const followupDocumentTypesMap = ComplianceParser.getFollowupDocumentTypesMap(
        res.data.documents
      );
      const map = ComplianceParser.parseComplianceDocumentMap(
        res.data.documents,
        followupDocumentTypesMap
      );

      return {
        items: res.data.items.map((item) =>
          ComplianceParser.parseCaregiverComplianceItem(item, map, caregiversMap)
        ),
        documents: res.data.documents.map((doc) =>
          ComplianceParser.parseComplianceDocument(doc, followupDocumentTypesMap)
        ),
      };
    });
  };

  fetchMissingFollowupItems = () => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/missing_followup_items`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<MissingFollowupItemsResponse>(url).then((res) => {
      return res.data.items;
    });
  };

  fetchStats = () => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/compliance_stats`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<ComplianceStatsResponse>(url).then((res) => res.data);
  };

  sendReminders = (caregiverIds: CaregiverId[], message: string) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/compliance/reminders`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.post(url, { caregiverIds, message });
  };

  nextPendingCaregiver =
    (): ng.IPromise<CompliancePrioritizedCaregiverByPendingDocuments | null> => {
      const url = this.endpoint({
        path: `agencies/:agencyId/agency_members/:agencyMemberId/next_pending_caregiver`,
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
        },
      });

      return this.api
        .post<Rawify<CompliancePrioritizedCaregiverByPendingDocumentsResponse>>(url)
        .then(({ data }): CompliancePrioritizedCaregiverByPendingDocuments | null => {
          if (data.data === null) {
            return null;
          }

          return {
            caregiver: {
              ...data.data.caregiver,
              dateOfBirth: fmap(data.data.caregiver.dateOfBirth, LocalDate.parse),
            },
            documents: data.data.documents.map((document) => ({
              ...document,
              fields: document.fields.map(parseComplianceV2FieldInstance),
              automationResult:
                document.automationResult === null
                  ? null
                  : {
                      ...document.automationResult,
                      runAt:
                        document.automationResult.runAt === null
                          ? null
                          : LocalDateTime.parse(document.automationResult.runAt),
                    },
            })),
            sectionsItems: data.data.sectionsItems,
            documentTypes: data.data.documentTypes.map((documentType) => ({
              ...documentType,
              fields: documentType.fields.map((field) => {
                if (field.type !== "Dropdown") {
                  return field;
                }

                return {
                  ...field,
                  possibleValues: field.possibleValues.map((option) => ({
                    ...option,
                    followupDocumentRequireData:
                      option.followupDocumentRequireData === null
                        ? null
                        : {
                            ...option.followupDocumentRequireData,
                            followupDocumentRequireDate:
                              option.followupDocumentRequireData.followupDocumentRequireDate ===
                              null
                                ? null
                                : LocalDate.parse(
                                    option.followupDocumentRequireData.followupDocumentRequireDate
                                  ),
                          },
                  })),
                };
              }),
            })),
            extra: data.data.extra,
            uploadedDocuments: data.data.uploadedDocuments.map((document) => ({
              ...document,
              uploadedAt: LocalDate.parse(document.uploadedAt),
            })),
          };
        });
    };

  skipPendingCaregiver = (params: { caregiverId: CaregiverId }) => {
    return this.api.put<void>(
      this.endpoint({
        path: "agencies/:agencyId/agency_members/:agencyMemberId/skip_pending_caregiver/:caregiverId",
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          caregiverId: params.caregiverId,
        },
      })
    );
  };

  createComplianceInstance = (params: {
    caregiverId: CaregiverId;
    data: ComplianceInstanceCreate;
  }) => {
    return this.api.post(
      this.endpoint({
        path: "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items",
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          caregiverId: params.caregiverId,
        },
      }),
      params.data
    );
  };

  rejectDocument = (params: {
    caregiverId: CaregiverId;
    uploadedDocumentId: CaregiverDocumentUploadId;
    reason: string | null;
  }) => {
    const body: ComplainceRejectDocument = {
      reason: params.reason,
    };

    return this.api.post(
      this.endpoint({
        path: "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/uploaded_documents/:uploadedDocumentId/reject",
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          caregiverId: params.caregiverId,
          uploadedDocumentId: params.uploadedDocumentId,
        },
      }),
      body
    );
  };
}

function parseComplianceV2FieldInstance(raw: Rawify<ComplianceV2FieldInstance>) {
  return match(raw)
    .with({ type: "Date" }, (field) => field)
    .with({ type: "Text" }, (field) => field)
    .with(
      { type: "Dropdown" },
      (field): ComplianceV2FieldInstance => ({
        ...field,
        possibleValues: field.possibleValues.map((value) => ({
          ...value,
          followupDocumentRequireData: fmap(
            value.followupDocumentRequireData,
            (followupDocumentRequireData) => ({
              ...followupDocumentRequireData,
              followupDocumentRequireDate: fmap(
                followupDocumentRequireData.followupDocumentRequireDate,
                LocalDate.parse
              ),
            })
          ),
        })),
      })
    )
    .exhaustive();
}
